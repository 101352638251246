<template>
    <modal v-if="show" @close="$emit('close')" size="large" class="outlined" :closeable="true">
        <template #header>
            <h1 class="text-center w-100 mb-3">About Produce Express</h1>

            <a href="#" class="close-modal" @click.prevent="$emit('close')">X</a>
        </template>

        <template #body>
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <p>Produce Express offers fresh produce, cheese, meat, dairy, local groceries and more! We offer  home delivery or contactless pick up directly at our warehouse through our drive thru. Produce Express also offers wholesale deliveries to schools, restaurants, grocery stores and food service establishments across southwestern Ontario.</p>
                        <p>We offer exceptional customer service and the freshest produce possible!</p>
                        <p>If you have any questions or inquiries, please contact us today! We would be happy to answer any questions that you might have.</p>
                    </div>

                    <div class="col-md-6">
                        <img src="/images/warehouse-about.jpeg" width="360px" height="360px" alt="Produce Express - Warehouse" loading="lazy"/>
                    </div>
                </div>
            </div>
        </template>

        <template #footer></template>
    </modal>
</template>

<script>
    import Modal from '@/components/Modal'

    export default {
        components: {
            Modal,
        },

        props: ['show'],
    }
</script>

<style scoped>
    .close-modal {
        color: #FF5047;
        line-height: 1;
    }

    .close-modal:hover {
        text-decoration: none;
    }

    h1:after {
        display: block;
        content: '';
        border-bottom: 2px solid #FF5047;
        width: 180px;
        height: 1px;
        margin: 0.5rem auto;
    }

    .col-md-6 {
        margin-bottom: 1rem;
    }

    .col-md-6:first-child {
        order: 1;
    }

    @media (min-width: 768px) {
        .col-md-6 {
            margin-bottom: 0;
        }


        .col-md-6:first-child {
            order: 0;
        }
    }
</style>