<template>
    <div>
        <div class="sidebar-fixed">
            <product-search class="content" />
        </div>

        <div class="main">
            <div class="content">
                <div class="mb-4">
                    <router-link to="/" class="btn btn-secondary btn-sm">Back to Catalog</router-link>
                </div>

                <h1 class="h1 mb-3">Order Summary</h1>

                <table id="table-summary" class="table table-sm">
                    <thead>
                        <tr>
                            <th class="d-none d-lg-table-cell"></th>
                            <th>Product</th>
                            <th class="text-center">Tax</th>
                            <th class="text-center" style="width:1%">Qty</th>
                            <th class="text-right d-none d-md-table-cell text-nowrap" style="width:1%">Line Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="product in productsInCart" :key="product.id">
                            <td class="d-none d-lg-table-cell"><img :src="product.image" width="50" height="50" /></td>
                            <td>
                                {{ product.title }}
                                <div class="price">
                                    <p v-if="product.price_2plus && product.quantity >= 2">
                                        <span class="text-muted strikethrough">${{ product.price }}</span>
                                        <span class="ml-2 price2-red">${{ product.price_2plus }} / {{ product.size }}</span>
                                    </p>
                                    <p v-else>
                                        ${{ product.price }} / <span class="">{{ product.size }}</span>
                                    </p>
                                    <ul v-if="product.subitems.length" class="basket-items">
                                        <li v-for="subitem in product.subitems" :key="subitem.id">
                                            {{ subitem.qty * 1 }} {{ subitem.name }} {{ subitem.unit }}
                                        </li>
                                    </ul>
                                </div>
                                <small><a href="#" @click.prevent="removeFromCart(product.id)">Remove</a></small>
                            </td>
                            <td class="text-center pt-3">{{ product.taxable ? 'Yes' : 'No' }}</td>
                            <td class="text-center">
                                <product-add-to-cart
                                    :product="product"
                                    :disableDecrement="product.quantity === 1"
                                ></product-add-to-cart>
                            </td>
                            <td class="text-right d-none d-md-table-cell pt-3">
                                <span v-if="product.price_2plus && product.quantity >= 2">
                                    ${{ (product.quantity * product.price_2plus).toFixed(2) }}
                                </span>
                                <span v-else>${{ (product.price * product.quantity).toFixed(2) }}</span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot v-show="productsInCart.length">
                        <tr>
                            <td colspan="5" class="text-right pl-0 pr-0">
                                <table class="totals">
                                    <tr v-if="tax">
                                        <td>Subtotal:</td>
                                        <td>${{ subtotal.toFixed(2) }}</td>
                                    </tr>
                                    <tr v-if="tax">
                                        <td>Tax:</td>
                                        <td>${{ tax.toFixed(2) }}</td>
                                    </tr>
                                    <tr>
                                        <td>Total:</td>
                                        <td>${{ total.toFixed(2) }}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                <div class="text-center mt-5" v-if="productsInCart.length">
                    <router-link :to="{ name: 'checkout' }" class="btn btn-primary btn-lg">Checkout</router-link>
                </div>

                <div v-if="!productsInCart.length" class="text-center">
                    - No products in cart -
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductAddToCart from '@/components/ProductAddToCart.vue'
import ProductSearch from '@/components/ProductSearch.vue';

export default {
    components: {
        ProductAddToCart,
        ProductSearch,
    },

    computed: {
        ...mapGetters([
            'subtotal',
            'tax',
            'total',
            'discount',
            'grandTotal',
            'productsInCart',
        ]),
    },

    methods: {
        removeFromCart (id) {
            this.$store.dispatch('removeItemFromCart', id);
        },
    },

    mounted () {
        window.scrollTo(0, 0);
    }
}
</script>

<style scoped>
    .sidebar-fixed {
        display: none;
    }

    @media (min-width: 768px) {
        .sidebar-fixed {
            display: initial;
        }
    }

    .totals {
        margin-left: auto;
        max-width: 300px;
    }

    .totals td {
        border: none;
        font-weight: 600;
    }

    .content {
        margin: 0 auto;
        max-width: 1200px;
    }
</style>