<template>
    <div class="container content">
        <div class="mb-4">
            <router-link :to="{ name: 'cart' }" class="btn btn-secondary btn-sm">Back to Cart</router-link>
        </div>

        <div class="alert alert-secondary login-reminder" v-if="!currentUser">
            <p class="mb-0">Already have an account? <a href="#" @click.prevent="$emit('login')">Log in</a> for a faster checkout.</p>
        </div>

        <h1 class="h1 mb-3">Checkout</h1>

        <form  action="#" method="post" class="form-checkout" @submit.prevent="handlePreSubmit">
            <div class="mb-2 gift-order">
                <label for="gift" class="d-inline-flex align-items-center mb-0">
                    <input type="checkbox" id="gift" v-on:click="toggle_gift()" v-model="gift" class="large" />
                    <span class="px-1">
                        <img src="/images/icons/gift.svg" width="18px" height="18px" style="margin-top: -5px; margin-left: 2px;" />
                    </span>
                    This order is a gift
                </label>
            </div>

            <div v-if="gift" class="row mb-2">
                <div class="col-md-6">
                    <div class="form-group row mx-0">
                        <label class="col-3 px-1 m-0" style="align-self: center">Gift For:</label>
                        <input type="text" maxlength="100" name="gift_to" id="gift_to" class="form-control col-9" v-model.trim="gift_to" :required="gift" placeholder="Recipient (required)"/>
                    </div>
                    <textarea v-model.trim="gift_message" type="text" maxlength="250" rows="4" style="resize:none" name="gift_message" id="gift_message" class="form-control" placeholder="Enter a gift message (optional)" />
                </div>
            </div>

            <transition name="fade">
                <div class="billing">
                    <h3>Billing Information:</h3>

                    <div class="row">
                        <div class="col-md mb-1">
                            <input v-model.trim="first_name" type="text" name="first_name" id="first_name" class="form-control" placeholder="First Name" required>
                        </div>
                        <div class="col-md mb-1">
                            <input v-model.trim="last_name" type="text" name="last_name" id="last_name" class="form-control" placeholder="Last Name" required>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md mb-1">
                            <input v-model.trim="phone" type="text" name="phone" id="phone" class="form-control" placeholder="Phone" required>
                        </div>
                        <div class="col-md mb-1">
                            <input v-model.trim="email" type="email" name="email" id="email" class="form-control" placeholder="Email" required>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md mb-1">
                            <input v-model.trim.lazy="address_1" type="text" name="address_1" id="address1" class="form-control" placeholder="Address" required>
                        </div>
                        <div class="col-md mb-1">
                            <input v-model.trim="address_2" type="text" name="address_2" id="address2" class="form-control" placeholder="Apt, Suite, Etc.">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md mb-1">
                            <input v-model.trim.lazy="city" type="text" name="city" id="city" class="form-control" placeholder="City" required>
                        </div>
                        <div class="col-md mb-1">
                            <input v-model.trim.lazy="province" type="text" name="province" id="province" class="form-control" placeholder="Province" required>
                        </div>
                        <div class="col-md mb-1">
                            <input
                                v-model.trim="postal"
                                type="text"
                                name="postal"
                                id="postal"
                                class="form-control deliveryPostal"
                                placeholder="Postal"
                                required
                                maxlength="7"
                                @keyup="replaceOWithZero"
                                :class="{ invalid: is_postal_invalid && postal.length >= 6 }"
                            >
                        </div>
                    </div>

                    <div v-show="isDelivery">
                        <input v-model="deliver_to_billing" type="checkbox" name="deliver_to_billing" id="deliver_to_billing" />
                        <label class="px-1" for="deliver_to_billing">Deliver to Billing Address</label>
                    </div>

                    <div class="delivery mb-2" v-show="isDelivery && !deliver_to_billing">
                        <h3>Delivery Information</h3>

                        <div class="row">
                            <div class="col-md mb-1">
                                <input v-model.trim.lazy="delivery_address_1" type="text" name="delivery_address_1" id="delivery_address1" class="form-control" placeholder="Address" :required="isDelivery && !deliver_to_billing">
                            </div>
                            <div class="col-md mb-1">
                                <input v-model.trim="delivery_address_2" type="text" name="delivery_address_2" id="delivery_address2" class="form-control" placeholder="Apt, Suite, Etc.">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md mb-1">
                                <input v-model.trim.lazy="delivery_city" type="text" name="delivery_city" id="delivery_city" class="form-control" placeholder="City" :required="isDelivery && !deliver_to_billing">
                            </div>
                            <div class="col-md mb-1">
                                <input v-model.trim.lazy="delivery_province" type="text" name="delivery_province" id="delivery_province" class="form-control" placeholder="Province" :required="isDelivery && !deliver_to_billing">
                            </div>
                            <div class="col-md mb-1">
                                <input
                                    v-model.trim="delivery_postal"
                                    type="text"
                                    name="delivery_postal"
                                    id="delivery_postal"
                                    class="form-control deliveryPostal"
                                    placeholder="Postal"
                                    maxlength="7"
                                    :required="isDelivery && !deliver_to_billing"
                                    @keyup="replaceOWithZero"
                                    :class="{ invalid: is_delivery_postal_invalid && delivery_postal.length >= 6 }"
                                >
                            </div>
                        </div>
                    </div>

                    <div v-if="!currentUser">
                        <label class="d-inline-flex align-items-center mb-0">
                            <input type="checkbox" name="gift" v-model="create_account" />
                            <span class="px-1">Create an account using this information</span>
                        </label>

                        <div v-if="create_account" class="row">
                            <div class="col-md-6">
                                <input v-model.trim="password" type="password" name="password" class="form-control mb-1" placeholder="Password" required />
                                <span v-if="form_errors.password" class="d-inline-block text-danger mb-2">{{ form_errors.password[0] }}</span>
                                <input v-model.trim="password_confirmation" type="password" name="password_confirmation" class="form-control mb-1" placeholder="Confirm Password" required />
                                <span v-if="form_errors.password_confirmation" class="d-inline-block text-danger mb-2">{{ form_errors.password_confirmation[0] }}</span>
                            </div>
                        </div>
                    </div>

                    <shipping
                        :shipping="shipping"
                        :available-produce-express-dates="availableProduceExpressDates"
                        :is-shipping-method-complete="isShippingMethodComplete"
                        :available-dates="pickup_dates"
                        :shipping-trigger="shippingTrigger"
                        :name="first_name + ' ' + last_name"
                        :phone="phone"
                        :email="email"
                    />

                    <div v-bind:class="{ faded: !isShippingMethodComplete }">
                        <h3 class="mt-3">Payment Information</h3>

                        <div style="font-size: 17px" class="mb-2">
                            <div v-if="isDelivery && delivery && delivery > 0">
                                Shipping: <span>${{ delivery.toFixed(2) }}</span> <br>
                            </div>

                            <div v-if="tax > 0">
                                Tax: <span>${{ tax.toFixed(2) }}</span>
                            </div>

                            Order Total:
                            <span v-show="total != grandTotal">
                                <s>${{ total.toFixed(2) }}</s>
                            </span>
                            ${{ grandTotal.toFixed(2) }}
                        </div>


                        <div class="row mb-2">
                            <div class="col-md-6">
                                <label for="promo_code" class="mb-0">Promo Code / Gift Card Code</label>

                                <div class="input-group">
                                    <input id="promo_code" type="text" class="form-control" v-model="promo_code" v-on:input="promo_code_error = ''" placeholder="Code" :disabled="hasPromo || !isShippingMethodComplete">
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-outline-secondary" v-on:click="applyPromo()" v-show="!hasPromo" :disabled="!isShippingMethodComplete">Apply</button>
                                    </div>
                                </div>

                                <div id="promo_code_applied" class="text_center_if_small small text-success pt-2" v-show="hasPromo">
                                    Promo Code / Gift Card Code Applied!
                                </div>
                                <div id="promo_code_errors" class="text_center_if_small small text-danger pt-2" v-text="promo_code_error"></div>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-md-6">
                                <label for="fundraising_code" class="mb-0">Fundraising Code</label>

                                <div class="input-group">
                                    <input id="fundraising_code" type="text" class="form-control" v-model="fundraising_code" v-on:input="fundraising_code_error = ''" placeholder="Code" :disabled="applied_fundraising_code != '' || !isShippingMethodComplete">
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-outline-secondary" v-on:click="applyFundraising()" v-show="applied_fundraising_code == ''" :disabled="!isShippingMethodComplete">Apply</button>
                                    </div>
                                </div>

                                <div id="fundraising_code_applied" class="text_center_if_small small text-success pt-2" v-show="applied_fundraising_code != ''">
                                    Fundraising Code Applied!
                                </div>
                                <div id="fundraising_code_errors" class="text_center_if_small small text-danger pt-2" v-text="fundraising_code_error"></div>
                            </div>
                        </div>

                        <div v-show="requiresPayment">
                            <label for="credit_card" class="mb-0">Credit Card</label>

                            <div class="row">
                                <div class="col-md" >
                                    <credit-card
                                        @credit-card-update="updateCardFields"
                                        :errorMessage="card_error"
                                        :isShippingMethodComplete="isShippingMethodComplete"
                                        :validate="requiresPayment"
                                        :use-vee-validate="false"
                                        :is-order-complete="is_order_complete"
                                    >
                                    </credit-card>
                                </div>

                                <div class="col-md-6 text_center_if_small" style="vertical-align: base">
                                    <img src="/images/we-accept.jpg" alt="We Accept Visa, Mastercard, Amex, and Discover" title="We Accept Visa, Mastercard, Amex, and Discover" width="180" height="42" style="margin-top: 2px"/>
                                </div>
                            </div>
                        </div>

                        <div class="text-center">
                            <p v-show="showShippingError" class="text-danger mt-4" style="line-height: 1.2">Please select a {{ shipping.method }} date before continuing</p>
                            <p v-show="is_order_processing" style="font-weight: bold;" class="mt-5">Processing your order, please wait ...</p>
                            <input
                                type="submit"
                                value="Submit Order"
                                class="btn btn-lg btn-primary"
                                :class="{ 'mt-5': !showShippingError }"
                                :disabled="isSubmitDisabled || !isShippingMethodComplete"
                                v-show="!is_order_processing"
                            >
                        </div>
                    </div>
                </div>
            </transition>
        </form>

        <modal v-if="show_modal" class="pricing outlined">
            <template #header>
                <h3>Pricing Update</h3>
            </template>

            <div slot="body">
                <p>The following product(s) have been updated since you started shopping:</p>

                <ol>
                    <li v-for="(change, index) in product_changes" v-html="change" :key="index"></li>
                </ol>

                <p>Your cart has been updated to reflect these changes.</p>
                <p>Please review your order and submit again.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="handleNavigateToCart">
                    View Cart
                </button>
            </div>
        </modal>

        <modal v-if="bad_field_modal" @close="bad_field_modal = false" :closeable="true">
            <div slot="header"><h3 class="mt-0">Missing Information</h3></div>
            <div slot="body">
                <ul>
                    <li v-for="(bad_field, index) in bad_fields" v-html="bad_field" :key="index"></li>
                </ul>

                <p>Please try again after correcting these fields.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="bad_field_modal = false">
                    Ok
                </button>
            </div>
        </modal>

        <modal v-if="problem_modal">
            <div slot="header"><h3 class="mt-0">Error placing order</h3></div>
            <div slot="body">
                <p>Sorry, we ran into a problem placing your order.</p>
                <p>Can you please refresh and try again?</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" onclick="location.reload()">
                    Refresh
                </button>
            </div>
        </modal>

        <modal v-if="popup_modal" @close="popup_modal = false">
            <div slot="header"><p>Please Review</p></div>
            <div slot="body" v-if="shipping.method == 'pickup'">
                <p>
                    You are picking this order up at:
                    <table class="m-2" style="display:block;">
                        <tr>
                            <td style="padding-right:1rem">Location:</td>
                            <td style="font-weight: bold"> {{shipping.pickupLocation.name}}</td>
                        </tr>
                        <tr>
                            <td>Date:</td>
                            <td style="font-weight: bold">  {{ formatDate(shipping.date) }}</td>
                        </tr>
                        <tr>
                            <td>Time slot:</td>
                            <td style="font-weight: bold">  {{shipping.time.label}}</td>
                        </tr>
                    </table>
                    If this is not correct, please press cancel and edit your selection.
                </p>
                <p>
                    Once submitted, you cannot add or remove items from your order or change the pick-up date or time.
                </p>
            </div>
            <div slot="body" v-if="shipping.method == 'delivery'">
                <p>
                    You are getting this order delivered to:
                    <span class="m-2" style="display:block; font-weight:bold">
                        {{shipping.deliveryAddress}} <br>
                        {{shipping.deliveryCity}} {{shipping.deliveryProvince}}, {{shipping.deliveryPostal}}
                    </span>
                    If this address is correct, please press continue. Otherwise, please press cancel and edit your address.
                </p>
                <p>
                    Once submitted, you cannot add or remove items from your order or change the delivery address or day.
                </p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary mr-2" @click="handleSubmit">
                    Continue
                </button>

                <button class="btn btn-secondary" @click="popup_modal = false">
                    Cancel
                </button>
            </div>
        </modal>

        <modal v-if="bad_slot_modal" @close="bad_slot_modal = false" :closeable="true">
            <div slot="header"><h3 class="mt-0">Pickup Unavailable</h3></div>
            <div slot="body">
                <p>Sorry, your chosen date/time are no longer available. Please review the available options and make another selection.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="bad_slot_modal = false">
                    Ok
                </button>
            </div>
        </modal>

        <modal v-if="bad_delivery_slot_modal" @close="bad_delivery_slot_modal = false" :closeable="true">
            <div slot="header"><h3 class="mt-0">Delivery Unavailable</h3></div>
            <div slot="body">
                <p>Sorry, your chosen date/time are no longer available. Please review the available options and make another selection.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="bad_delivery_slot_modal = false">
                    Ok
                </button>
            </div>
        </modal>

        <modal v-if="bad_address_modal" @close="bad_address_modal = false" :closeable="true">
            <div slot="header"><h3 class="mt-0">Delivery Unavailable</h3></div>
            <div slot="body">
                <p>Sorry, delivery is no longer available in your area. Please check back later.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="bad_address_modal = false">
                    Ok
                </button>
            </div>
        </modal>

        <modal v-if="bad_cart_modal">
            <div slot="header"><h3 class="mt-0">Product(s) Unavailable</h3></div>
            <div slot="body">
                <p>One or more products are not available for your pickup/delivery location and have been removed from your cart.</p>
                <p>Please review your order and submit again.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="handleNavigateToCart">
                    View Cart
                </button>
            </div>
        </modal>

        <modal v-if="bad_basket_modal" @close="bad_basket_modal = false" class="cart-updates">
            <div slot="header">
                <h3 class="mt-0">Cart Update</h3>
            </div>
            <div slot="body">
                <p>Gift basket items have been updated since you started shopping.</p>
                <p>Please review your order and try again.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="handleNavigateToCart">
                    View Cart
                </button>
            </div>
        </modal>

        <modal v-if="show_cart_has_updated_modal">
            <div slot="header">
                <h3 class="mt-0">Cart Update</h3>
            </div>
            <div slot="body">
                <p>Your cart has been updated with items previously saved to your account.</p>

                <p>Please review before continuing.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-primary" @click="handleNavigateToCart">
                    View Cart
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import Shipping from '@/components/Shipping.vue'
    import CreditCard from '@/components/CreditCard.vue'
    import Modal from '@/components/Modal.vue'

    let initialData = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address_1: '',
        address_2: '',
        city: '',
        province: '',
        postal: '',
        gift: false,
        gift_to: '',
        gift_message: '',

        deliver_to_billing: true,
        delivery_address_1: '',
        delivery_address_2: '',
        delivery_city: '',
        delivery_province: '',
        delivery_postal: '',

        create_account: false,
        password: '',
        password_confirmation: '',

        cardnumber: '',
        expiry: '',
        cvd: '',
        card_error: '',

        is_order_complete: false,
        is_order_processing: false,
        is_postal_invalid: false,
        is_delivery_postal_invalid: false,

        pickup_dates: [],
        payment_status: '',
        product_changes: [],
        show_modal: false,
        show_cart_has_updated_modal: false,

        bad_field_modal: false,
        bad_fields: [],
        problem_modal: false,
        popup_modal: false,
        bad_slot_modal: false,
        bad_delivery_slot_modal: false,
        bad_address_modal: false,
        bad_cart_modal: false,
        bad_basket_modal: false,

        fundraising_code: '',
        fundraising_code_error: '',
        applied_fundraising_code: '',

        promo_code: '',
        promo_code_error: '',

        searchInput: null,

        showShippingError: false,

        shipping: {
            method: '',
            date: '',
            dateLabel: '',
            time: '',
            deliveryAddress: '',
            deliveryPostal: '',
            deliveryCity: '',
            deliveryLat: '',
            deliveryLng: '',
            deliveryProvince: '',
            deliveryNotes: '',
            deliveryZone: '',
            pickupLocation: ''
        },

        availableProduceExpressDates: [],
        shippingTrigger: '',
    }

    export default {
        name: 'Checkout',

        components: {
            Shipping,
            CreditCard,
            Modal,
        },

        metaInfo () {
            return {
                meta: [
                    { charset: 'utf-8' },
                    { vmid: 'robots', name: 'robots', content: 'noindex' }

                ]
            }
        },

        data () {
            return {
                ...initialData,

                form_errors: [],

                originalGuestCart: [],
            }
        },

        computed: {
            ...mapGetters([
                'subtotal',
                'tax',
                'total',
                'discount',
                'grandTotal',
                'productsInCart',
                'promo',
                'delivery'
            ]),

            ...mapState({
                currentUser: state => state.user.currentUser,
            }),

            isDelivery() {
                return this.shipping.method === 'delivery' ? true : false;
            },

            requiresPayment () {
                return this.grandTotal > 0;
            },

            isShippingMethodComplete() {
                if (this.shipping.method === 'pickup' && this.shipping.pickupLocation.id === 0 && this.shipping.date && this.shipping.time) {
                    return true;
                }
                else if (this.shipping.method === 'pickup' && this.shipping.pickupLocation.id !== 0 && this.shipping.date) {
                    return true;
                }
                else if (this.shipping.method === 'delivery' && this.shipping.date) {
                    return true;
                }

                return false;
            },

            isSubmitDisabled() {
                return this.is_order_processing
                    || new Date().getDay() === 0
                    || this.productsInCart.length === 0
                    || (this.grandTotal > 0 && this.cardnumber === '' && this.expiry === '' && this.cvd === '')
                    || this.is_postal_invalid
                    || this.is_delivery_postal_invalid
                    || !this.shippingAddressValid;
            },

            hasPromo () {
                return this.promo.code;
            },

            shippingAddress() {
                if(this.shipping.method == 'delivery')
                {
                    if(this.deliver_to_billing)
                    {
                        let data = {
                            firstname: this.first_name,
                            lastname: this.last_name,
                            phone: this.phone,
                            email: this.email,
                            address: this.address_1,
                            city: this.city,
                            province: this.province,
                            postal: this.postal
                        }
                        return data;
                    }
                    else
                    {
                        let data = {
                            firstname: this.first_name,
                            lastname: this.last_name,
                            phone: this.phone,
                            email: this.email,
                            address: this.delivery_address_1,
                            city: this.delivery_city,
                            province: this.delivery_province,
                            postal: this.delivery_postal
                        }
                        return data;
                    }
                }
                else //pickup order
                {
                    let data = {
                            firstname: "",
                            lastname: "",
                            phone: "",
                            email: "",
                            address: "",
                            city: "",
                            province: "",
                            postal: "",
                        }
                    return data;
                }
            },

            shippingAddressValid() {
                //Make sure there is something in each of the address fields for deliveries
                //and a valid postal code is input for deliveries
                return (this.shipping.method == 'pickup' ||
                        (this.shipping.method == 'delivery'
                        && this.shippingAddress.address
                        && this.shippingAddress.city
                        && this.shippingAddress.province
                        && this.shippingAddress.postal
                        && !this.is_delivery_postal_invalid ? true : false))
            }
        },

        methods: {
            replaceOWithZero() {
                this.delivery_postal = this.delivery_postal.replace('o', '0').replace('O', 0);
                this.postal = this.postal.replace('o', '0').replace('O', 0);
            },

            updateCardFields(data) {
                for (const [property, val] of Object.entries(data)) {
                    this[property] = val;
                }
            },

            applyPromo() {
                var promoCode = '';
                var promoCodeAmount = 0;
                var promoCodeType = '';

                this.axios
                    .post(process.env.VUE_APP_API + '/apply_promo_code_outlet', this._data)
                    .then(d => {
                        if (d.data.result == 'fail')
                        {
                            this.promo_code_error = d.data.message;
                            promoCode = '';
                            promoCodeAmount = 0;
                            promoCodeType = '';
                        }
                        else
                        {
                            this.promo_code_error = '';
                            promoCode = d.data.applied_promo_code.code;
                            promoCodeAmount = d.data.applied_promo_code.discount_amount;
                            promoCodeType = d.data.applied_promo_code.type;
                        }

                        this.$store.commit('setPromoCode', {
                            code: promoCode,
                            amount: promoCodeAmount,
                            type: promoCodeType,
                        });
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },

            applyFundraising() {
                this.axios
                    .post(process.env.VUE_APP_API + '/apply_fundraising_code_outlet', this._data)
                    .then(d => {
                        if (d.data.result == 'fail')
                        {
                            this.fundraising_code_error = d.data.message;
                            this.applied_fundraising_code = '';
                        }
                        else
                        {
                            this.fundraising_code_error = '';
                            this.applied_fundraising_code = d.data.applied_fundraising_code.code;
                        }
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },

            toggle_gift() {
                if(this.gift == true)
                {   //the box was just unchecked
                    this.gift_to = '';
                    this.gift_message = '';
                }
            },

            handlePreSubmit () {
                this.showShippingError = false;

                if (this.isShippingMethodComplete) {
                    this.popup_modal = true
                } else {
                    this.showShippingError = true;
                }
            },

            handleSubmit () {
                this.form_errors = [];
                this.popup_modal = false;
                this.card_error = '';

                if (this.is_order_processing) {
                    return;
                } else {
                    this.is_order_processing = true;
                }

                this.product_changes = [];

                var data = {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    phone: this.phone,
                    email: this.email,
                    address_1: this.address_1,
                    address_2: this.address_2,
                    city: this.city,
                    province: this.province,
                    postal: this.postal,
                    delivery_address_1: this.delivery_address_1,
                    delivery_address_2: this.delivery_address_2,
                    delivery_city: this.delivery_city,
                    delivery_province: this.delivery_province,
                    delivery_postal: this.delivery_postal,
                    delivery_notes: this.shipping.deliveryNotes,
                    delivery_zone: this.shipping.deliveryZone,
                    lat: this.shipping.deliveryLat,
                    lng: this.shipping.deliveryLng,
                    gift: this.gift,
                    gift_to: this.gift_to,
                    gift_message: this.gift_message,
                    products: this.productsInCart,
                    order_total: this.grandTotal,
                    applied_fundraising_code: this.applied_fundraising_code,
                    applied_promo_code: this.promo.code,
                    shipping_method: this.shipping.method,
                    date: this.shipping.date,
                    pickup_time: this.shipping.time.id,
                    pickup_location: this.shipping.pickupLocation,
                    cardnumber: this.cardnumber,
                    expiry: this.expiry,
                    cvd: this.cvd,
                    abandoned_delivery_checkout_id: this.abandoned_delivery_checkout_id,
                    create_account: this.create_account,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                };

                this.axios({
                    method: 'post',
                    timeout: 30000,    // 30 seconds timeout
                    url: process.env.VUE_APP_API + '/outlet/order',
                    data: data
                }).then(response => {
                    if (response.data.result === 'success') {
                        this.is_order_complete = true;
                        this.$emit('order-complete', { email: this.email });
                        this.$store.dispatch('emptyCart');
                        this.$store.commit('resetPromo');

                        this.$nextTick(function() {
                            this.resetCheckout();
                            this.$router.push('/thankyou');
                        });

                        this.$store.dispatch('updateUserProfile', {
                            first_name: data.first_name,
                            last_name: data.last_name,
                            phone: data.phone,
                            billing_address_1: data.address_1,
                            billing_address_2: data.address_2,
                            billing_city: data.city,
                            billing_province: data.province,
                            billing_postal: data.postal,
                            shipping_address_1: data.delivery_address_1,
                            shipping_address_2: data.delivery_address_2,
                            shipping_city: data.delivery_city,
                            shipping_province: data.delivery_province,
                            shipping_postal: data.delivery_postal,
                        });
                    } else if (response.data.result === 'change') {
                        this.diffProducts(response.data.payload);

                        if (this.product_changes.length) {
                            this.show_modal = true;
                        }

                        this.$store.commit('updateProducts', response.data.payload);
                    }
                    else if (response.data.result === 'bad_fields') {
                        this.bad_fields = response.data.bad_fields
                        this.bad_field_modal = true;
                    }
                    else if (response.data.result === 'bad_slot') {
                        if (this.shipping.pickupLocation && this.shipping.pickupLocation.id === 0) {
                            this.availableProduceExpressDates = response.data.payload;
                        } else {
                            this.pickup_dates = response.data.payload;
                        }
                        this.bad_slot_modal = true;
                    }
                    else if (response.data.result === 'bad_delivery_slot') {
                        this.shippingTrigger = response.data.result;
                        this.bad_delivery_slot_modal = true;
                    }
                    else if (response.data.result === 'bad_address') {
                        this.bad_address_modal = true;
                        this.shippingTrigger = response.data.result;
                    }
                    else if (response.data.result === 'promo_fail') {
                        this.$store.commit('resetPromo');
                        this.promo_code_error = response.data.message;
                    }
                    else if (response.data.result === 'fundraising_fail') {
                        this.applied_fundraising_code = '';
                        this.fundraising_code_error = response.data.message;
                        window.scroll(0, 0); // Bring fundraising error into view
                    }
                    else if (response.data.result === 'bad_cart') {
                        let invalidIds = response.data.ids;

                        this.bad_cart_modal = true;
                        this.$store.commit('updateProducts', response.data.payload);

                        if (invalidIds.length) {
                            invalidIds.forEach(id => {
                                this.$store.dispatch('removeItemFromCart', id);
                            });
                        }
                    }
                    else if (response.data.result === 'bad_basket') {
                        this.bad_basket_modal = true;
                        this.$store.commit('updateProducts', response.data.payload);
                        window.scrollTo(0, 0);
                    }
                    else if (response.data.result === 'fail') {
                        this.card_error = response.data.message;
                    }
                    else {
                        this.axios.get(process.env.VUE_APP_API + '/report_error', {
                            params: {
                                error: "Intent Failure: " + response.data.result,
                                name: this.first_name + " " + this.last_name,
                                phone: this.phone,
                                email: this.email,
                            }
                        });
                        this.problem_modal=true;
                    }
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.form_errors = error.response.data.errors;

                        // errors for products field means we somehow submitted without products. Prompt user to try again.
                        if (this.form_errors.products.length) {
                            this.problem_modal = true;
                        }
                    } else {
                        var error_message = '';

                        if (error.code !== undefined) error_message = error.code; // Timeout
                        if (error.response !== undefined) error_message = error.response.status; // 400/500 series

                        this.axios.get(process.env.VUE_APP_API + '/report_error', {
                            params: {
                                error: error_message,
                                name: this.first_name + " " + this.last_name,
                                phone: this.phone,
                                email: this.email,
                            }
                        });

                        this.problem_modal = true;
                    }
                })
                .finally(() => {
                    this.is_order_processing = false;
                });
            },

            resetCheckout () {
                const userProfileFields = [
                    'first_name',
                    'last_name',
                    'email',
                    'phone',
                    'address_1',
                    'address_2',
                    'city',
                    'province',
                    'postal',
                    'delivery_address_1',
                    'delivery_address_2',
                    'delivery_city',
                    'delivery_province',
                    'delivery_postal'
                ];

                for (const property in initialData) {
                    if (typeof this[property] === 'object' && this[property] !== null) {
                        this[property] = {
                            ...initialData[property],
                        }
                    } else if (this.currentUser && userProfileFields.includes(property)) {
                        this[property] = this.mapUserFieldToCheckout(property);
                    } else {
                        this[property] = initialData[property];
                    }
                }

                this.fetchPeDates();
            },

            mapUserFieldToCheckout (property) {
                if (property === 'first_name') return this.currentUser.first_name ?? '';
                else if (property === 'last_name') return this.currentUser.last_name ?? '';
                else if (property === 'phone') return this.currentUser.phone ?? '';
                else if (property === 'email') return this.currentUser.email ?? '';
                else if (property === 'address_1') return this.currentUser.billing_address_1 ?? '';
                else if (property === 'address_2') return this.currentUser.billing_address_2 ?? '';
                else if (property === 'city') return this.currentUser.billing_city ?? '';
                else if (property === 'province') return this.currentUser.billing_province ?? '';
                else if (property === 'postal') return this.currentUser.billing_postal ?? '';
                else if (property === 'delivery_address_1') return this.currentUser.shipping_address_1 ?? '';
                else if (property === 'delivery_address_2') return this.currentUser.shipping_address_2 ?? '';
                else if (property === 'delivery_city') return this.currentUser.shipping_city ?? '';
                else if (property === 'delivery_province') return this.currentUser.shipping_province ?? '';
                else if (property === 'delivery_postal') return this.currentUser.shipping_postal ?? '';
                else return '';
            },

            diffProducts(updatedProducts) {
                this.productsInCart.forEach(product => {
                    var updatedProduct = updatedProducts.find(u => product.id === u.id);

                    if (!updatedProduct || (updatedProduct.stock !== null & updatedProduct.stock <= 0) || updatedProduct.active !== product.active) {
                        this.product_changes.push(`${product.title} is no longer available`);
                    }
                    else {
                        if (updatedProduct.price !== product.price || updatedProduct.unit !== product.size) {
                            this.product_changes.push(`${product.title} have changed from <strong>$${product.price}\\${product.size}</strong> to <strong>$${updatedProduct.price}\\${updatedProduct.unit}</strong>`);
                        }

                        if (updatedProduct.stock !== null && updatedProduct.stock < product.quantity) {
                            this.product_changes.push(`${product.title} available quantity has changed from <strong>${product.quantity}</strong> to <strong>${updatedProduct.stock}</strong>`);
                        }
                    }
                });
            },

            closeModal() {
                this.show_modal = false;
                document.getElementById('table-summary').scrollIntoView();
            },

            formatDate(input) {
                let date = new Date(input);
                let options = {weekday: 'long', month: 'short',  day: 'numeric', year: 'numeric', timeZone: 'UTC'};

                return date.toLocaleDateString('en-US', options);
            },

            fetchPeDates () {
                this.axios.get(process.env.VUE_APP_API + '/catalog/fetch-pe-available-dates').then(response => {
                    this.availableProduceExpressDates = response.data;
                });
            },

            handleNavigateToCart () {
                this.show_modal = false;
                this.bad_cart_modal = false;
                this.bad_basket_modal = false;
                this.show_cart_has_updated_modal = false;
                this.$router.push('/cart');
            },
        },

        watch: {
            postal(newValue) {
                if (newValue === null || newValue === undefined) {
                    return;
                }

                let validPostalCode = /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/i;

                let postal = newValue.replace(' ', '');
                // if (! validPostalCode.test(newValue) && newValue.replace(' ', '').length >= 6) {
                if (! validPostalCode.test(postal)) {
                    this.is_postal_invalid = true;
                } else {
                    this.is_postal_invalid = false;
                }

                if (this.shipping.method === 'delivery' && this.deliver_to_billing) {
                    this.shipping.deliveryPostal = newValue;
                }
            },

            address_1(newValue) {
                if (this.shipping.method == 'delivery' && this.deliver_to_billing) {
                    this.shipping.deliveryAddress = newValue;
                }
            },

            city(newValue) {
                if (this.shipping.method == 'delivery' && this.deliver_to_billing) {
                    this.shipping.deliveryCity = newValue;
                }
            },

            province(newValue) {
                if (this.shipping.method == 'delivery' && this.deliver_to_billing) {
                    this.shipping.deliveryProvince = newValue;
                }
            },

            delivery_postal(newValue) {
                if (newValue === null || newValue === undefined) {
                    return;
                }

                let validPostalCode = /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/i;

                let postal = newValue.replace(' ', '');
                // if (! validPostalCode.test(newValue) && newValue.replace(' ', '').length >= 6) {
                if (! validPostalCode.test(postal)) {
                    this.is_delivery_postal_invalid = true;
                } else {
                    this.is_delivery_postal_invalid = false;
                }

                if (this.shipping.method === 'delivery' && !this.deliver_to_billing) {
                    this.shipping.deliveryPostal = newValue;
                }
            },

            delivery_address_1(newValue) {
                if (this.shipping.method === 'delivery' && !this.deliver_to_billing) {
                    this.shipping.deliveryAddress = newValue;
                }
            },

            delivery_city(newValue) {
                if (this.shipping.method === 'delivery' && !this.deliver_to_billing) {
                    this.shipping.deliveryCity = newValue;
                }
            },

            delivery_province(newValue) {
                if (this.shipping.method === 'delivery' && !this.deliver_to_billing) {
                    this.shipping.deliveryProvince = newValue;
                }
            },

            deliver_to_billing () {
                let postal = this.deliver_to_billing ? this.postal : this.delivery_postal;
                let address = this.deliver_to_billing ? this.address_1 : this.delivery_address_1;
                let city = this.deliver_to_billing ? this.city : this.delivery_city;
                let province = this.deliver_to_billing ? this.province : this.delivery_province;

                this.shipping.deliveryPostal = postal;
                this.shipping.deliveryAddress = address;
                this.shipping.deliveryCity = city;
                this.shipping.deliveryProvince = province;
            },

            'shipping.method': {
                handler () {
                    if (this.shipping.method === 'delivery') {
                        let postal = this.deliver_to_billing ? this.postal : this.delivery_postal;
                        let address = this.deliver_to_billing ? this.address_1 : this.delivery_address_1;
                        let city = this.deliver_to_billing ? this.city : this.delivery_city;
                        let province = this.deliver_to_billing ? this.province : this.delivery_province;

                        this.shipping.deliveryPostal = postal;
                        this.shipping.deliveryAddress = address;
                        this.shipping.deliveryCity = city;
                        this.shipping.deliveryProvince = province;
                    }
                }
            },

            currentUser () {
                this.resetCheckout();

                if (this.originalGuestCart.length) {
                    this.productsInCart.forEach(p1 => {
                        const match = this.originalGuestCart.find(p2 => p2.id === p1.id && p2.quantity === p1.quantity);

                        if (!match) {
                            this.show_cart_has_updated_modal = true;
                            return;
                        }
                    });
                }
            },
        },

        mounted () {
            this.promo_code = this.promo.code;

            this.resetCheckout();
        },

        activated () {
            if (!this.shipping.date) {
                this.fetchPeDates();
            }

            window.scrollTo(0, 0);
        },

        beforeRouteEnter (to, from, next) {
            next(vm => {
                if (vm.productsInCart.length === 0) {
                    vm.$router.replace({ name: 'cart' });
                } else if (!vm.currentUser) {
                    vm.originalGuestCart = JSON.parse(JSON.stringify(vm.productsInCart));
                }
            });
        },

        beforeRouteLeave (to, from, next) {
            this.originalGuestCart = [];
            next();
        }
    }
</script>

<style scoped>
    h3 {
        margin: 1rem 0;
    }

    .pricing h3 {
        margin-top: 0;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .invalid {
        border-color: #cc0000;
        box-shadow: none;
    }

    .basket-items {
        list-style-type: none;
        padding-left: 2px;
        font-size: 14px;
    }

    .cart-updates p {
        font-size: 1.125rem;
    }

    .gift-order label {
        font-size: 22px;
    }

    .login-reminder {
        padding: 0.5rem 1rem !important;
    }

    .login-reminder p {
        font-size: 16px;
        line-height: 1.3;
    }
</style>