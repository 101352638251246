<template>
    <form @submit.prevent="handleSubmit">
        <div class="mb-3">
            <h2 class="h4">Account Information</h2>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>First Name</label>
                        <input type="text" class="form-control" v-model="form.first_name" />
                        <span v-if="errors.first_name" class="text-danger">{{ errors.first_name[0] }}</span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>Last Name</label>
                        <input type="text" class="form-control" v-model="form.last_name" />
                        <span v-if="errors.last_name" class="text-danger">{{ errors.last_name[0] }}</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" v-model="form.email" disabled readonly/>
                    </div>

                    <div class="form-group">
                        <label>Password</label>
                        <input type="password" class="form-control" v-model="form.password" />
                        <input v-if="form.password" type="password" class="form-control mt-1" v-model="form.password_confirmation" placeholder="Password Confirm" />
                        <span v-if="errors.password" class="text-danger">{{ errors.password[0] }}</span>
                        <span v-else class="text-muted"><small>Leave blank if you don't want to change it.</small></span>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>Phone</label>
                        <input type="text" class="form-control" v-model="form.phone" />
                        <span v-if="errors.phone" class="text-danger">{{ errors.phone[0] }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <h2 class="h4">Billing Address</h2>

            <div class="row">
                <div class="col-md-8">
                    <div class="form-group">
                        <label>Address 1</label>
                        <input type="text" class="form-control" v-model="form.billing_address_1" />
                        <span v-if="errors.billing_address_1" class="text-danger">{{ errors.billing_address_1[0] }}</span>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>Address 2</label>
                        <input type="text" class="form-control" v-model="form.billing_address_2" />
                        <span v-if="errors.billing_address_2" class="text-danger">{{ errors.billing_address_2[0] }}</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>City</label>
                        <input type="text" class="form-control" v-model="form.billing_city" />
                        <span v-if="errors.billing_city" class="text-danger">{{ errors.billing_city[0] }}</span>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>Province</label>
                        <input type="text" class="form-control" v-model="form.billing_province" />
                        <span v-if="errors.billing_province" class="text-danger">{{ errors.billing_province[0] }}</span>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>Postal</label>
                        <input type="text" class="form-control" v-model="form.billing_postal" />
                        <span v-if="errors.billing_postal" class="text-danger">{{ errors.billing_postal[0] }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <h2 class="h4">Shipping Address</h2>

            <div class="form-group d-flex align-items-center">
                <input type="checkbox" v-model="form.shipping_same_as_billing" class="mr-2 large" id="shipping_same_as_billing" />
                <label class="mb-0" for="shipping_same_as_billing">Shipping same as billing</label>
            </div>

            <div v-if="!form.shipping_same_as_billing">
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-group">
                            <label>Address 1</label>
                            <input type="text" class="form-control" v-model="form.shipping_address_1" />
                            <span v-if="errors.shipping_address_1" class="text-danger">{{ errors.shipping_address_1[0] }}</span>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Address 2</label>
                            <input type="text" class="form-control" v-model="form.shipping_address_2" />
                            <span v-if="errors.shipping_address_2" class="text-danger">{{ errors.shipping_address_2[0] }}</span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>City</label>
                            <input type="text" class="form-control" v-model="form.shipping_city" />
                            <span v-if="errors.shipping_city" class="text-danger">{{ errors.shipping_city[0] }}</span>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Province</label>
                            <input type="text" class="form-control" v-model="form.shipping_province" />
                            <span v-if="errors.shipping_province" class="text-danger">{{ errors.shipping_province[0] }}</span>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Postal</label>
                            <input type="text" class="form-control" v-model="form.shipping_postal" />
                            <span v-if="errors.shipping_postal" class="text-danger">{{ errors.shipping_postal[0] }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center">
            <input type="submit" class="btn btn-primary" value="Save" />
            <span v-if="saving" class="ml-3"><img src="/images/icons/spinner.svg" width="24px" height="24px" class="spin" alt="Loading..." /></span>
        </div>
    </form>
</template>

<script>
const saveUrl = process.env.VUE_APP_API + '/api/user/profile';

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            form: {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                password_confirmation: '',
                phone: '',
                billing_address_1: '',
                billing_address_2: '',
                billing_city: '',
                billing_province: '',
                billing_postal: '',
                shipping_address_1: '',
                shipping_address_2: '',
                shipping_city: '',
                shipping_province: '',
                shipping_postal: '',
                shipping_same_as_billing: true,
            },

            errors: [],

            saving: false,
        }
    },

    methods: {
        handleSubmit () {
            this.errors = [];
            this.saving = true;
            this.$emit('saving');

            this.axios.post(saveUrl, this.form)
                .then(response => {
                    this.form.password = '';
                    this.form.password_confirmation = '';
                    this.$store.dispatch('updateUserProfile', response.data);
                    this.$emit('saved');
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        return;
                    }

                    throw error;
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        resetForm () {
            this.form.first_name = this.user.first_name;
            this.form.last_name = this.user.last_name;
            this.form.email = this.user.email;
            this.form.password = '';
            this.form.password_confirmation = '';
            this.form.phone = this.user.phone;
            this.form.billing_address_1 = this.user.billing_address_1;
            this.form.billing_address_2 = this.user.billing_address_2;
            this.form.billing_city = this.user.billing_city;
            this.form.billing_province = this.user.billing_province;
            this.form.billing_postal = this.user.billing_postal;
            this.form.shipping_address_1 = this.user.shipping_address_1;
            this.form.shipping_address_2 = this.user.shipping_address_2;
            this.form.shipping_city = this.user.shipping_city;
            this.form.shipping_province = this.user.shipping_province;
            this.form.shipping_postal = this.user.shipping_postal;
            this.form.shipping_same_as_billing = this.user.shipping_same_as_billing;
        },
    },

    watch: {
        user: {
            deep: true,
            handler () {
                this.resetForm();
            }
        }
    },

    mounted () {
        this.resetForm();
    }
}
</script>

<style scoped>
.form-group {
    margin-bottom: 0.75rem;
}
</style>