import Vue from 'vue'
import Vuex from 'vuex'
import products from './modules/products'
import user from './modules/user'
import axios from 'axios'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    strict: debug,

    state: {
        announcements: [],
        categories: [],
    },


    actions: {
        fetchAnnouncements({ commit }) {
            return axios
                .get(process.env.VUE_APP_API + '/catalog/fetch-announcements')
                .then(response => {
                    commit('setAnnouncements', response.data)
                })
                .catch(error => {
                    console.log(error)
                });
        },


        fetchCategories({ commit }) {
            return axios
                .get(process.env.VUE_APP_API + '/get_categories')
                .then(response => {
                    commit('setCategories', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
    },

    mutations: {
        setAnnouncements (state, payload) {
            state.announcements = payload;
        },

        setCategories (state, payload) {
            state.categories = payload.map(category => {
                return {
                    id: category.id,
                    name: category.title,
                    slug: category.slug,
                    isSection: true,
                    subcategories: category.subcategories.map(s => {
                        return {
                            id: s.id,
                            name: s.title,
                            slug: s.slug,
                        }
                    })
                }
            });
        },
    },

    modules: {
        products,
        user,
    },
})
