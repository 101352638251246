<template>
    <modal v-if="show" @close="$emit('close')" size="larger" class="outlined" :closeable="true">
        <template #header>
            <h1 class="text-center w-100 mb-3">Contact Us</h1>

            <a href="#" class="close-modal" @click.prevent="$emit('close')">X</a>
        </template>

        <template #body>
            <div class="container">
                <div class="row">
                    <div class="col-md-4 text-center">
                        <div class="step">
                            <img src="/images/icons/phone.svg" width="24px" height="24px" />
                        </div>

                        <p class="mb-0">
                            <strong>Give Us a Call!</strong>
                            <br> (519) 539-9333
                        </p>
                    </div>

                    <div class="col-md-4 text-center">
                        <div class="step">
                            <img src="/images/icons/envelop.svg" width="24px" height="24px" />
                        </div>
                        <p class="mb-0">
                            <strong>Send Us an Email!</strong>
                            <br> sales@fresherproduce.ca
                        </p>
                    </div>

                    <div class="col-md-4 text-center">
                        <div class="step">
                            <img src="/images/icons/location.svg" width="24px" height="24px" />
                        </div>

                        <div class="mb-0">
                            <strong>Follow Us on Social Media!</strong>

                            <div class="d-flex align-items-center justify-content-center my-1 mx-auto">
                                <a href="https://www.facebook.com/Produce-Express-105105928123651/" target="_blank" class="mr-2">
                                    <img src="/icon-facebook.png" width="32px" height="32px" alt="Facebook" />
                                </a>

                                <a href="https://www.instagram.com/produce.express" target="_blank">
                                    <img src="/icon-instagram.png" width="32px" height="32px" alt="Instagram" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #footer></template>
    </modal>
</template>

<script>
    import Modal from '@/components/Modal'

    export default {
        components: {
            Modal,
        },

        props: ['show'],
    }
</script>

<style scoped>
    .close-modal {
        color: #FF5047;
        line-height: 1;
    }

    .close-modal:hover {
        text-decoration: none;
    }

    h1 {
        font-size: 2rem
    }

    h1:after {
        display: block;
        content: '';
        border-bottom: 2px solid #FF5047;
        width: 180px;
        height: 1px;
        margin: 0.5rem auto;
    }

    .col-md-4 {
        margin-bottom: 1.5rem;
    }

    .col-md-4:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 768px) {
        .col-md-4 {
            margin-bottom: 0;
        }
    }

    .step {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        font-size: 1.75rem;
        content: "";
        background: #FF5047;
        color: #fff;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        flex: 0 0 auto;
        margin: 0 auto 1rem;
    }
</style>