import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Product from '../views/Product.vue'
import Cart from '../views/Cart.vue'
import Checkout from '../views/Checkout.vue'
import OrderComplete from '../views/OrderComplete.vue'
import EditProfile from '../views/EditProfile.vue'
import OrderHistory from '../views/OrderHistory.vue'
import PageNotFound from '../views/PageNotFound.vue'
import store from '@/store';

Vue.use(VueRouter)

const loginMessage = 'Authentication required, please sign in.';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        props: route => ({ login: route.query.login, loginMessage: loginMessage }),
    },
    {
        path: '/product/:slug',
        name: 'product',
        component: Product,
    },
    {
        path: '/cart',
        name: 'cart',
        component: Cart,
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: Checkout,
    },
    {
        path: '/404',
        name: 'pageNotFound',
        component: PageNotFound,
    },
    {
        path: '/thankyou',
        name: 'orderComplete',
        component: OrderComplete,
    },
    {
        path: '/referral',
        redirect: { path: '/', hash: '#referral'},
    },
    {
        path: '/my-account/profile',
        name: 'editProfile',
        component: EditProfile,
        meta: { requiresAuth: true },
    },
    {
        path: '/my-account/orders',
        name: 'orderHistory',
        component: OrderHistory,
        meta: { requiresAuth: true },
    },
    {
        path: '*',
        redirect: '/404'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !store.state.user.currentUser) {
        store.dispatch('getCurrentUser').then(() => {
            if (store.state.user.currentUser === null) {
                next({ name: 'home', replace: true });
            } else {
                next();
            }
        });
    } else {
        if (store.state.user.currentUser === null) {
            store.dispatch('getCurrentUser');
        }

        next();
    }
});

export default router
