import axios from 'axios';

const loginUrl = process.env.VUE_APP_API + '/login';
const logoutUrl = process.env.VUE_APP_API + '/logout';
const registerUrl = process.env.VUE_APP_API + '/register';
const resetPasswordRequestUrl = process.env.VUE_APP_API + '/password/email';
const currentUserUrl = process.env.VUE_APP_API + '/api/user/current';

const state = () => ({
    currentUser: null,
});

const getters = {

};

const actions = {
    getCurrentUser ({ commit }) {
        return axios.get(currentUserUrl).then((response) => {
            let user = null;

            if (response.status === 200 && response.data) {
                user = response.data;
            }

            commit('setCurrentUser', user);
        });
    },

    signIn ({ commit, rootGetters, dispatch }, payload) {
        const guestCart = rootGetters.productsInCart.map(p => {
            return {
                id: null,
                user_id: null,
                item_id: p.id,
                quantity: p.quantity,
            }
        });

        dispatch('emptyCart');

        return axios
            .post(loginUrl, {
                username: payload.email,
                password: payload.password,
            })
            .then(response => {
                if (response.status === 200) {
                    const cart = response.data.cart.concat(guestCart);

                    commit('setCurrentUser', { ...response.data.user });
                    commit('setCart', cart);
                    dispatch('saveUserCart');
                }
            })
            .catch(error => {
                // Failed to sign in so restore guest cart.
                commit('setCart', guestCart);

                throw error;
            });
    },

    signOut ({ commit, dispatch }) {
        return axios.post(logoutUrl).then(() => {
            commit('setCurrentUser', null);
            dispatch('fetchCart');
        });
    },

    register ({ commit, rootGetters, dispatch }, payload) {
        const guestCart = rootGetters.productsInCart.map(p => {
            return {
                id: null,
                user_id: null,
                item_id: p.id,
                quantity: p.quantity,
            }
        });

        dispatch('emptyCart');

        return axios
            .post(registerUrl, {
                first_name: payload.first_name,
                last_name: payload.last_name,
                email: payload.email,
                password: payload.password,
                password_confirmation: payload.password_confirmation,
                billing_address_1: payload.billing_address_1,
                billing_address_2: payload.billing_address_2,
                billing_city: payload.billing_city,
                billing_province: payload.billing_province,
                billing_postal: payload.billing_postal,
                phone: payload.phone,
            })
            .then(response => {
                if (response.status === 200) {
                    commit('setCurrentUser', { ...response.data.user });
                    commit('setCart', guestCart);
                    dispatch('saveUserCart');
                }
            })
            .catch(error => {
                // Failed to sign in so restore guest cart.
                commit('setCart', guestCart);

                throw error;
            });
    },

    requestPasswordReset (context, payload) {
        return axios.post(resetPasswordRequestUrl, {
            email: payload.email,
        });
    },

    updateUserProfile ({ commit, state }, data) {
        if (!state.currentUser) {
            return;
        }

        commit('updateProfile', data);
    }
};

const mutations = {
    setCurrentUser (state, payload) {
        state.currentUser = payload;
    },

    updateProfile (state, payload) {
        state.currentUser.first_name = payload.first_name;
        state.currentUser.last_name = payload.last_name;
        state.currentUser.phone = payload.phone;
        state.currentUser.billing_address_1 = payload.billing_address_1;
        state.currentUser.billing_address_2 = payload.billing_address_2;
        state.currentUser.billing_city = payload.billing_city;
        state.currentUser.billing_province = payload.billing_province;
        state.currentUser.billing_postal = payload.billing_postal;
        state.currentUser.shipping_address_1 = payload.shipping_address_1;
        state.currentUser.shipping_address_2 = payload.shipping_address_2;
        state.currentUser.shipping_city = payload.shipping_city;
        state.currentUser.shipping_province = payload.shipping_province;
        state.currentUser.shipping_postal = payload.shipping_postal;
        state.currentUser.shipping_same_as_billing = payload.shipping_same_as_billing;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};

