<template>
    <div>
        <h3>Shipping Method</h3>

        <div class="shipping-method-container row mb-">
            <div class="col-md-6 col-lg-4">
                <div class="shipping-method d-flex align-items-center">
                    <div class="mr-4">
                        <input v-model="shipping.method" type="radio" value="pickup" id="pickup" class="mr-1" />
                        <label for="pickup">Pickup</label>
                    </div>

                    <div class="mr-4">
                        <input v-model="shipping.method" type="radio" value="delivery" id="delivery" class="mr-1" />
                        <label for="delivery">Delivery</label>
                    </div>
                </div>

                <div v-if="shipping.method === 'pickup'" class="pickup-location">
                    <div class="d-flex mb-2">
                        <span>At: </span>

                        <select v-model="shipping.pickupLocation" class="form-control form-control-sm form-location ml-3" name="pickupLocation"  v-if="pickupLocations.length > 0">
                            <option :value="produceExpress">{{ produceExpress.name }} {{ produceExpress.city }}</option>
                            <option :value="location" v-for="location in pickupLocations" :key="location.id">{{ location.name }}</option>
                        </select>
                    </div>

                    <div v-if="shipping.method === 'pickup'" class="pickup_address">
                        <p class="mb-0">{{ shipping.pickupLocation.street_1 }} {{ shipping.pickupLocation.city }}</p>
                        <p class="mb-0" v-if="pickupDaysOfWeek" style="font-size: 16px; color: #458700; line-height:1.375;">Pickup Available: <br> <span v-text="pickupDaysOfWeek"></span> <span v-text="pickupHours"></span></p>
                    </div>

                </div>

                <div v-if="shipping.method === 'delivery'">
                    <p class="mb-1" style="font-size: 14px;" v-if="deliveryFee !== false">
                        Delivery ${{ this.deliveryFee }}.
                        <span v-if="deliveryFreeDeliveryAvailable">Free delivery for orders over $75</span>
                        <span v-else>Free delivery over $75 <strong>not available</strong> in this area</span>
                    </p>

                    <p class="mb-0" style="font-size: 16px; color: #458700; line-height:1.375;" v-if="deliveryDaysOfWeek">Delivery available weekly in this area on: <span v-text="deliveryDaysOfWeek"></span></p>

                    <p class="mb-0" style="font-size: 16px; line-height:1.375;" v-if="deliveryMessage" v-text="deliveryMessage"></p>
                </div>
            </div>

            <div class="col-md-6 col-lg-8">
                <div class="d-flex align-items-end" style="height: 100%;">
                    <div v-if="!pickupLoading && !deliveryLoading && !deliveryMessage && (shipping.date === '' || shipping.time === '') && (shipping.method === 'pickup' || (shipping.method === 'delivery' && !isDeliveryAddressInvalid))" class="hint">
                        Please select a time and date before continuing
                    </div>
                </div>
            </div>
        </div>

        <div v-show="shipping.method === 'pickup'">
            <div class="available-dates mt-3">
                <div v-if="pickupLoading" style="font-size: 2rem; text-align: center;">
                    <img src="/images/icons/spinner.svg" width="36px" height="36px" class="spin" alt="Loading..." />
                </div>

                <div v-else>
                    <div class="card"
                        v-for="(item, index) in pickupDates"
                        :key="index"
                        :class="{selected: shipping.date == index}"
                        v-on:click.self="setShippingDate(index, item)"
                    >
                        <h4 class="mb-2 d-inline-block" v-html="item.title" v-on:click.self="setShippingDate(index, item)"></h4>

                        <div v-if="item.status === 'available'">
                            <div class="time" :class="{ 'text-left' : item.slots.length > 1 }" v-show="shipping.date == index">
                                <div v-for="(slot, index) in item.slots" :key="index" class="custom-control custom-radio custom-control-inline mt-1">
                                    <div v-if="item.slots.length > 1">
                                        <input
                                            v-model="shipping.time"
                                            :id="item.title + '_' + slot.id"
                                            :name="item.title + '_' + slot.id"
                                            :value="slot"
                                            @click="handleScrollToBottom"
                                            type="radio"
                                            class="custom-control-input"
                                        />
                                        <label class="custom-control-label" :for="item.title + '_' + slot.id" v-text="slot.label"></label>
                                    </div>

                                    <span v-text="slot.label" v-else></span>
                                </div>
                            </div>
                        </div>

                        <div v-else>Upcoming*</div>
                    </div>
                </div>
                <div v-if="pickupHasUpcomingDates"><small class="text-muted" style="line-height: 1.2">* Orders must be placed within 2 business days of pickup<span v-if="!pickupHasAvailableDates">, please come back later.</span></small></div>
                <div v-if="pickupUnavailable">No available dates, please check back later.</div>
            </div>
        </div>

        <div v-show="shipping.method === 'delivery'">
            <div class="available-dates mt-3">
                <div v-if="deliveryLoading" style="font-size: 2rem; text-align: center;">
                    <img src="/images/icons/spinner.svg" width="36px" height="36px" class="spin" alt="Loading..." />
                </div>

                <div v-else >
                    <p class="mb-3 text-danger" v-show="isDeliveryAddressInvalid">
                        Please enter the delivery address, city, province and postal code.
                    </p>
                    <div class="card"
                        v-for="(item, index) in deliveryDates"
                        :key="index"
                        :class="{selected: shipping.date == index}"
                        v-on:click="setShippingDate(index, item)"
                    >
                        <h4 :style="item.status === 'upcoming' ? 'margin-bottom: 0' : ''">{{ item.title }}</h4>
                        <p v-if="item.status === 'available'">{{ item.slots[0].label }}</p>
                        <p v-if="item.status === 'upcoming'" style="line-height: 1; margin-bottom: 8px;">
                            <small style="font-size: 70%;">{{ item.formatted }}</small>
                        </p>
                        <p v-if="item.status === 'upcoming'">Upcoming*</p>
                        <p v-if="item.status === 'soldout'">Sold Out</p>
                    </div>
                </div>

                <div v-if="deliveryDates.length !== 0"><p><small class="text-muted">* Orders must be placed within 2 business days of delivery<span v-if="!deliveryHasAvailableDates">, please come back later.</span></small></p></div>

                <div v-show="isShippingMethodComplete">
                    <label>Delivery Instructions (Optional)</label>
                    <textarea-autosize class="form-control mb-5" v-model="shipping.deliveryNotes" maxlength="255" :min-height="34"></textarea-autosize>
                </div>
            </div>
        </div>

        <modal v-if="showInvalidModal" @close="handleInvalidModalClose">
            <div slot="header">
                <h3>
                    Product<span v-show="invalidProducts.length > 1">s</span>
                    Unavailable
                </h3>
            </div>
            <div slot="body">
                <p>The following items are not available at the selected location and have been removed from your cart.</p>

                <ul>
                    <li v-for="product in invalidProducts" :key="product.id">
                        {{ product.title }} - ${{ product.price }} / {{ product.size }}
                    </li>
                </ul>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="handleInvalidModalClose">
                    Ok
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Modal from '@/components/Modal.vue'

    export default {
        components: {
            Modal,
        },

        props: [
            'shipping',
            'abandoned_delivery_checkout_id',
            'isShippingMethodComplete',
            'availableDates',
            'availableProduceExpressDates',
            'shippingTrigger',
            'name',
            'phone',
            'email',
        ],

        data () {
            return {
                shippingMethodMessage: '',

                pickupHours: '',
                pickupDaysOfWeek: '',
                pickupDates: [],
                pickupLoading: false,
                pickupHasUpcomingDates: false,
                pickupHasAvailableDates: false,
                pickupUnavailable: false,

                deliveryDaysOfWeek: '',
                deliveryDates: [],
                deliveryFee: false,
                deliveryFreeDeliveryAvailable: false,
                deliveryMessage: false,
                deliveryHasUpcomingDates: false,
                deliveryHasAvailableDates: false,
                deliveryLoading: false,

                isDeliveryAddressInvalid: true, //false when address 1, city, and provice are set. Ignores address 2

                produceExpress: {
                    id: 0,
                    name: 'Produce Express',
                    city: 'Woodstock',
                    street_1: '454 Innovation Drive',
                },

                pickupLocations: [],
                invalidProducts: [],
                showInvalidModal: false,
            }
        },

        computed: {
            ...mapGetters([
                'productsInCart',
            ]),
        },

        methods: {
            setShippingDate (date, data) {
                if (data.status !== 'available') {
                    return;
                }

                this.shipping.date = date;
                this.shipping.dateLabel = data.title;

                if (data.slots.length === 1) {
                    this.shipping.time = data.slots[0];
                    this.handleScrollToBottom();
                } else {
                    this.shipping.time = "";
                }
            },

            resetDelivery() {
                this.deliveryDates = [];
                this.deliveryFee = false;
                this.deliveryHasUpcomingDates = false;
                this.deliveryHasAvailableDates = false;
                this.deliveryDaysOfWeek = '';
                this.deliveryMessage = '';
                this.shipping.date = '';
            },

            handleScrollToBottom () {
                setTimeout(function() {
                    window.scrollTo(0, document.body.scrollHeight);
                }, 100);
            },

            fetchDeliveryDates () {
                this.deliveryLoading = true;

                var postal = this.shipping.deliveryPostal.replace(' ', '');
                var address = this.shipping.deliveryAddress;
                var city = this.shipping.deliveryCity;
                var province = this.shipping.deliveryProvince;

                this.axios
                    .get(process.env.VUE_APP_API + '/outlet/deliveries/find-dates', {
                        params: {
                            name: this.name,
                            email: this.email,
                            phone: this.phone,
                            address: address,
                            city: city,
                            province: province,
                            postal: postal,
                            abandoned_delivery_checkout_id: this.abandoned_delivery_checkout_id
                        }
                    })
                    .then(response => {
                        if (response.data.result === 'success') {
                            this.shipping.deliveryLat = response.data.lat;
                            this.shipping.deliveryLng = response.data.lng;
                            this.shipping.deliveryZone = response.data.zone_id;
                            this.deliveryDates = response.data.dates;
                            this.deliveryFee = parseFloat(response.data.fee);
                            this.deliveryFreeDeliveryAvailable = response.data.free_delivery_available;
                            this.deliveryDaysOfWeek = response.data.delivery_days;

                            if (this.deliveryDates.length === 0) {
                                this.deliveryMessage = 'No available dates, please check back later.';
                            } else {
                                // Set the abandoned delivery checkout id at the same time
                                this.$emit('abandoned-checkout-update', response.data.abandoned_delivery_checkout_id)

                                for (let date in this.deliveryDates) {
                                    if (this.deliveryDates[date].status === 'upcoming') this.deliveryHasUpcomingDates = true;
                                    if (this.deliveryDates[date].status === 'available') this.deliveryHasAvailableDates = true;
                                }
                                this.deliveryMessage = '';
                            }
                        }
                        else if (response.data.result === 'invalid_postal') {
                            this.deliveryMessage = 'Delivery is currently not available in your area.';
                        }
                        else if (response.data.result === 'invalid_address') {
                            this.deliveryMessage = response.data.reason;
                        }
                        else {
                               this.deliveryMessage = 'No available dates, please check back later.';
                        }

                        this.$store.commit('setShipping', {
                            method: this.shipping.method,
                            fee: this.deliveryFee,
                            freeAvailable: this.deliveryFreeDeliveryAvailable,
                        });
                    }).finally(() => {
                        this.deliveryLoading = false;
                    });
            },

            handleInvalidModalClose () {
                this.invalidProducts.forEach(p => {
                    this.$store.dispatch('removeItemFromCart', p.id);
                });

                this.invalidProducts = [];
                this.showInvalidModal = false;
            },
        },

        mounted () {
            this.$watch((vm) => (vm.shipping.method, vm.shipping.pickupLocation, Date.now()), () => {
                this.shipping.date = "";
                this.shipping.time = "";
                this.pickupHasUpcomingDates = false;
                this.pickupHasAvailableDates = false;
                this.pickupUnavailable = false;

                if (this.shipping.method !== 'pickup') {
                    return;
                }

                if (this.shipping.pickupLocation && this.shipping.pickupLocation.id === 0) {
                    this.pickupDates = this.availableProduceExpressDates;
                    this.pickupDaysOfWeek = '';
                    this.pickupHours = '';
                } else {
                    this.pickupDates = [];
                    this.pickupLoading = true;

                    var data = {
                        params: { pickup_location: this.shipping.pickupLocation.id }
                    };

                    this.axios.get(process.env.VUE_APP_API + '/outlet/orders/find-dates', data).then(response => {
                        this.pickupLoading = false;
                        this.pickupDates = response.data.dates;
                        this.pickupDaysOfWeek = response.data.daysOfWeek;
                        this.pickupHours = response.data.hours;

                        if (this.pickupDates.length === 0) {
                            this.pickupUnavailable = true;
                        } else {
                            for (let date in this.pickupDates) {
                                if (this.pickupDates[date].status === 'upcoming') this.pickupHasUpcomingDates = true;
                                if (this.pickupDates[date].status === 'available') this.pickupHasAvailableDates = true;
                            }
                        }

                        // Autoselect date/time if there's only one applicable
                        var dates = Object.keys(this.pickupDates);

                        if ((dates.length === 1 && !this.pickupHasUpcomingDates) || (dates.length == 2 && this.pickupHasAvailableDates)) {
                            this.shipping.date = dates[0];
                            this.shipping.time = this.pickupDates[dates[0]].slots[0];
                            this.handleScrollToBottom();
                        }
                    })
                    .catch(() => {
                        this.pickupLoading = false;
                    });
                }
            });

            this.$watch((vm) => (vm.shipping.deliveryAddress, vm.shipping.deliveryCity, vm.shipping.deliveryProvince, Date.now()), () => {
                if (this.shipping.deliveryAddress && this.shipping.deliveryCity && this.shipping.deliveryProvince) {
                    this.isDeliveryAddressInvalid = false;

                    if (this.shipping.method === 'delivery') {
                        this.fetchDeliveryDates();
                    }
                } else {
                    this.resetDelivery();
                    this.isDeliveryAddressInvalid = true;
                }
            });

            this.axios.get(process.env.VUE_APP_API + '/catalog/fetch-pickup-locations').then(response => {
                this.pickupLocations = response.data;
            });

            this.shipping.pickupLocation = this.produceExpress;
        },

        watch: {
            'shipping.deliveryPostal': {
                handler (newVal) {
                    let validPostalCode = /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/i;

                    if (validPostalCode.test(newVal)) {
                        this.resetDelivery();
                        this.isDeliveryAddressInvalid = false;

                        if(this.shipping.method == 'delivery') {
                            this.fetchDeliveryDates();
                        }
                    } else {
                       this.resetDelivery();
                       this.isDeliveryAddressInvalid = true;
                    }
                }
            },

            'shipping.method': {
                handler () {
                    this.shipping.date = '';
                    this.shipping.time = '';

                    if (this.shipping.method === 'delivery') {
                        this.shipping.pickupLocation = this.produceExpress;
                        // ! Test without this line.
                        this.fetchDeliveryDates();
                    }

                    this.$store.commit('setShippingMethod', this.shipping.method);
                }
            },

            'shipping.pickupLocation' (newValue) {
                this.$store.commit('setPickupLocation', newValue.id);

                this.productsInCart.forEach(p => {
                    if (p.store_excludes && p.store_excludes.includes(newValue.id)) {
                        this.invalidProducts.push(p);
                    }
                });

                if (this.invalidProducts.length) {
                    this.showInvalidModal = true;
                }
            },

            pickupLocation () {
                this.shipping.date = '';
            },

            selectedDate () {
                this.shipping.time = '';
            },

            pickupDates (newValue) {
                if (newValue === null || newValue.length === 0) {
                    this.pickupUnavailable = true;
                } else {
                    this.pickupUnavailable = false;
                }
            },

            availableProduceExpressDates (newValue) {
                if (this.shipping.pickupLocation && this.shipping.pickupLocation.id === 0) {
                    this.pickupDates = newValue;
                    this.shipping.date = '';
                    this.shipping.time = '';
                }
            },

            availableDates (newValue) {
                this.pickupDates = newValue;
                this.shipping.date = '';
                this.shipping.time = '';
            },

            availableDeliveryDates (newValue) {
                this.deliveryDates = newValue;
                this.shipping.date = '';
                this.shipping.time = '';
            },

            shippingTrigger (newValue) {
                if (newValue === 'bad_address') {
                    this.resetDelivery();
                    this.deliveryMessage = 'Delivery is currently not available in your area.';
                } else if (newValue === 'bad_delivery_slot') {
                    this.resetDelivery();
                    this.fetchDeliveryDates();
                }
            }
        },
    }
</script>

<style scoped>
h3 {
    margin: 1rem 0;
}

.pickup-location {
    flex: 1;
}

@media screen and (min-width: 640px) {
    .pickup-location {
        flex: unset;
    }
}

.available-dates {
    max-width: 600px;
}

.available-dates p {
    line-height: 1.2;
}

.form-location {
    max-width: 240px;
}

.available-dates .card {
    display: inline-block;
    padding: 12px;
    width: 100%;
    margin-bottom: 6px;
    text-align: center;
    vertical-align: top;
    min-height: 102px;
    border: 2px solid #e0dede;
}

.available-dates .card:hover {
    cursor: pointer;
    border: 1px solid #85C71E;
    background-color: #edfff5;
    box-sizing: border-box;
}

.available-dates .card.selected {
    border: 2px solid #85C71E;
}

.available-dates .card.selected:hover {
    cursor: default;
}

.available-dates .card h4 {
    font-size: 18px;
    margin-top: 0;
}

.available-dates .card h4 span {
    display: block;
    font-size: 14px;
}

.available-dates .custom-control {
    padding-left: 0;
}

.available-dates .custom-control-label {
    padding-left: 2rem;
    text-transform: capitalize;
    line-height: 1.5rem;
}

.available-dates .custom-control-label::after,
.available-dates .custom-control-label::before {
    left: 0.75rem;
}

.available-dates .custom-control-label:hover {
    cursor: pointer;
}

.available-dates .time {
    font-size: 14px;
}

@media screen and (min-width: 700px) {
    .available-dates .card {
        width: calc(50% - 6px);
        margin-right: 8px;
    }

    .available-dates .card:last-child {
        width: calc(50% - 2px);
        margin-right: 0;
    }
}

.shipping-method-container {
    position: relative;
}

.shipping-method-container .hint {
    position: relative;
    background: #85C71E;
    border-radius: 6px;
    padding: 1rem 0.5rem;
    color: #fff;
    max-width: 240px;
    text-align: center;
    font-size: 18px;
    line-height: 1.2;
    margin: 1.5rem auto 1.5rem;
}

.shipping-method-container .hint:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 120px;
    margin-left: -20px;
    width: 0;
    height: 0;
    border-top: solid 20px #85C71E;
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
}

@media (min-width: 768px) {
    .shipping-method-container .hint {
        margin-left: 0;
        margin-right: 0;
    }
}

.pickup_address p {
    font-size: 16px;
}
</style>