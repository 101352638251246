<template>
  <div class="h-100 d-flex flex-column align-items-center sidebar">
        <form @submit.prevent class="w-100">
            <h3 class="h4">Missing an item? <span class="d-none d-lg-inline">Search for it!</span></h3>
            <input type="text"  v-model="search" class="form-control rounded-pill search" placeholder="Search..."/>
        </form>

        <ul class="results pr-4">
            <li v-for="product in filteredProducts" :key="product.id">
                <div class="row">
                    <div class="col-4">
                        <div class="img-wrapper">
                            <img :src="product.image" class="image-thumbnail mr-1" width="80" height="80" />
                        </div>
                    </div>

                    <div class="col-8">
                        <p class="mb-0">{{ product.title }}</p>

                        <div>
                            <p v-if="product.price_2plus" class="price2-grn mb-0">Buy 2 for {{ price2plus(product) | toCurrency }}</p>
                            <p class="mb-0">${{ product.price }}/{{ product.size }}</p>
                        </div>

                        <product-add-to-cart
                            :product="product"
                            :show-add-to-cart-button="true"
                            btn-size="btn-sm"
                            class="mt-1"
                        ></product-add-to-cart>
                    </div>
                </div>
            </li>
        </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductAddToCart from '@/components/ProductAddToCart.vue'

export default {
    components: {
        ProductAddToCart,
    },

    data() {
        return {
            search: '',
        }
    },

    computed: {
        ...mapGetters(['products', 'productsInCart']),

        filteredProducts () {
            const cartIds = this.productsInCart.map(p => p.id);


            return this.products.filter(p => {
                const search = this.search === ''
                        || p.title.toUpperCase().indexOf(this.search.toUpperCase()) !== -1
                        || p.description.toUpperCase().indexOf(this.search.toUpperCase())  !== -1;

                return p.active && !cartIds.includes(p.id) && search;
            }).sort((a, b) => {
                if (a.title === b.title) return 0;

                return a.title > b.title ? 1 : -1;
            }).slice(0, 50);
        }
    },

    methods: {
        price2plus (product) {
            if (product.price_2plus === null) return false;

            return Math.round(product.price_2plus * 2 * 100) / 100;
        },
    }
}
</script>

<style scoped>
    h3 {
        font-size: 22px;
        font-weight: normal;
    }

    .results {
        margin-top: 1.5rem;
        margin-bottom: 0;
        list-style-type: none;
        overflow: auto;
        padding-left: 0;
    }

    .results li {
        padding: 1rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    .results li p,
    .results li span {
        font-size: 16px;
    }

    .results img {
        max-height: 100px;
        width: auto;
    }

    .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .search {
        background: url('/images/icons/search.svg') 97% center no-repeat;
        padding-right: 32px;
    }
</style>