<template>
    <div class="container-xl content">
        <div class="row">
            <div class="col-md-3 col-lg-2">
                <user-sidebar />
            </div>

            <div class="col-md-9 col-lg-10">
                <h1 class="mb-3">Edit Profile</h1>

                <alert :message="message" v-if="message" :alert-style="'alert-success'" @close="message = ''" />

                <form-user-profile :user="currentUser" v-if="currentUser" @saved="handleSaved" @saving="handleSaving" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import UserSidebar from '@/components/UserSidebar.vue';
import FormUserProfile from '@/components/FormUserProfile.vue';
import Alert from '@/components/Alert.vue';


export default {
    name: 'edit-profile',

    components: {
        UserSidebar,
        FormUserProfile,
        Alert,
    },

    data() {
        return {
            message: '',
        }
    },

    methods: {
        handleSaving () {
            this.message = '';
        },

        handleSaved () {
            this.message = 'User Profile Saved.'
            window.scrollTo(0, 0);
        },
    },

    computed: {
        ...mapState({
            currentUser: state => state.user.currentUser,
        }),
    },

    // beforeRouteEnter (to, from, next) {
    //     console.log('beforeRouteEnter');
    //     this.$store.dispatch('getCurrentUser').then((response) => {
    //         console.log(response);
    //     });

    //     next();
    // },
}
</script>

<style scoped>
label {
    font-size: 16px;
    margin-bottom: 0.25rem;
}

.form-group {
    margin-bottom: 0.5rem;
}
</style>