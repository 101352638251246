<template>
    <div class="loading">
        <div class="loadingio-spinner-eclipse-9meyr7ikt3n">
            <div class="ldio-xbcpe3d3kte">
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>
.loading {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 75vh;
}

@keyframes ldio-v7mfwa9k708 {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}
.ldio-xbcpe3d3kte div {
  position: absolute;
  animation: ldio-v7mfwa9k708 1s linear infinite;
  width: 96px;
  height: 96px;
  top: 12px;
  left: 12px;
  border-radius: 50%;
  box-shadow: 0 2.4px 0 0 #85c71e;
  transform-origin: 48px 49.199999999999996px;
}
.loadingio-spinner-eclipse-9meyr7ikt3n {
  width: 120px;
  height: 120px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-xbcpe3d3kte {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-xbcpe3d3kte div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>

