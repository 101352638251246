<template>
    <form action="#" @submit.prevent="handleLogin">
        <div class="row">
            <div class="col-md-12">
                <div class="mb-1">
                    <input type="email" v-model="form.email" class="form-control" placeholder="Email" ref="email" />
                    <span v-if="errors.email" class="text-danger">{{ errors.email[0] }}</span>
                </div>

                <div class="mb-1">
                    <input type="password" v-model="form.password" class="form-control" placeholder="Password"/>
                    <span v-if="errors.password" class="text-danger">{{ errors.password[0] }}</span>
                </div>

                <div class="mb-1">
                    <input type="password" v-model="form.password_confirmation" class="form-control" placeholder="Confirm Password"/>
                    <span v-if="errors.password_confirmation" class="text-danger">{{ errors.password_confirmation[0] }}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.first_name" class="form-control" placeholder="First Name"/>
                    <span v-if="errors.first_name" class="text-danger">{{ errors.first_name[0] }}</span>
                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.last_name" class="form-control" placeholder="Last Name"/>
                    <span v-if="errors.last_name" class="text-danger">{{ errors.last_name[0] }}</span>
                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.phone" class="form-control" placeholder="Phone" />
                    <span v-if="errors.phone" class="text-danger">{{ errors.phone[0] }}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8">
                <div class="mb-1">
                    <input type="text" v-model="form.billing_address_1" class="form-control" placeholder="Address" />
                    <span v-if="errors.billing_address_1" class="text-danger">{{ errors.billing_address_1[0] }}</span>
                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.billing_address_2" class="form-control" placeholder="Apt, Suite, Etc."/>
                    <span v-if="errors.billing_address_2" class="text-danger">{{ errors.billing_address_2[0] }}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.billing_city" class="form-control" placeholder="City" />
                    <span v-if="errors.billing_city" class="text-danger">{{ errors.billing_city[0] }}</span>
                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.billing_province" class="form-control" placeholder="Province"/>
                    <span v-if="errors.billing_province" class="text-danger">{{ errors.billing_province[0] }}</span>
                </div>
            </div>

            <div class="col-md-4">
                <div class="mb-1">
                    <input type="text" v-model="form.billing_postal" class="form-control" placeholder="Postal" />
                    <span v-if="errors.billing_postal" class="text-danger">{{ errors.billing_postal[0] }}</span>
                </div>
            </div>
        </div>

        <div class="text-right mt-3">
            <input type="submit" class="btn btn-primary" value="Create" />
            <button type="button" @click="$emit('change-form', 'login')" class="btn btn-outline-secondary ml-2">Cancel</button>
        </div>
    </form>
</template>

<script>
export default {
    data() {
        return {
            form: {
                email: '',
                password: '',
                password_confirmation: '',
                first_name: '',
                last_name: '',
                billing_address_1: '',
                billing_address_2: '',
                billing_city: '',
                billing_province: '',
                billing_postal: '',
                phone: '',
            },

            errors: '',
        }
    },

    methods: {
        handleLogin () {
            this.errors = '';

            this.$store.dispatch('register', this.form)
                .then(() => {
                    this.$emit('change-form', 'registered');
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
    },

    mounted () {
        this.$refs.email.focus();
    }
}
</script>

<style>

</style>