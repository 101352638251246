<template>
    <div class="products" v-bind:class="{ disabled_faded: new Date().getDay() == 0 }" id="products">
        <div v-show="!isFiltering">
            <div v-for="(category, index) in availableCategories" :key="index" class="category" v-show="hasProducts(category) && isCategoryVisible(category)">
                <h2 class="category-title">{{ category.name }}</h2>

                <div class="row">
                    <product
                        v-for="product in categorizedProducts(category.slug)"
                        :product="product"
                        :key="product.id"
                        v-show="isVisible(product)"
                        @quickview="handleQuickview"
                    ></product>
                </div>

                <div v-for="subcategory in category.subcategories" :key="subcategory.id" v-show="hasProducts(subcategory) && isCategoryVisible(subcategory)">
                    <h3 class="subcategory-title">{{ subcategory.name }}</h3>

                    <div class="row">
                        <product
                            v-for="product in categorizedProducts(subcategory.slug)"
                            :product="product"
                            :key="product.id"
                            v-show="isVisible(product)"
                            @quickview="handleQuickview"
                        ></product>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="isFiltering">
            <h2 class="category-title" v-show="selectedFilter && !brandFilters.length">{{ selectedFilter }}</h2>

            <div class="row">
                <product
                    v-for="product in products"
                    :product="product"
                    :key="product.id"
                    v-show="isVisible(product)"
                    @quickview="handleQuickview"
                ></product>

                <h5 class="text-center" v-show="productsVisible === 0" style="width: 100%;">
                    0 matches found <span v-if="searchTerm">for "{{ this.searchTerm }}"</span>
                </h5>
            </div>
        </div>

        <product-quickview
            :product="quickviewProduct"
            :show="showQuickview"
            @close="showQuickview = false"
        ></product-quickview>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import Product from '@/components/Product.vue';
    import ProductQuickview from '@/components/ProductQuickview.vue';

    export default {
        components: {
            Product,
            ProductQuickview,
        },

        data () {
            return {
                quickviewProduct: null,
                showQuickview: false,

                staticCategories: [
                    { id: null, name: 'Specials', slug: 'special', isSection: true,  subcategories: []},
                    { id: null, name: 'My Favourites', slug: 'pinned',  isSection: false, subcategories: []},
                    { id: null, name: 'Local Only', slug: 'locally_grown', isSection: false, subcategories: []},
                ]
            }
        },

        computed: {
            ...mapGetters(['products']),

            ...mapState({
                searchTerm: state => state.products.searchTerm,
                foodFilters: state => state.products.foodFilters,
                promoFilters: state => state.products.promoFilters,
                brandFilters: state => state.products.brandFilters,
                categories: state => state.categories,
            }),

            isFiltering () {
                // return this.searchTerm.length || this.brandFilters.length;
                return this.searchTerm.length || this.foodFilters.length || this.promoFilters.length || this.brandFilters.length;
            },

            selectedFilter () {
                // If the selected filter is a subcategory, return the parent name and subcategory name
                // Otherwise return the parent
                let parent_category = null;
                let subcategory = null;

                parent_category = this.topLevelCategories.find(c => {
                    return this.foodFilters.includes(c.slug) || this.promoFilters.includes(c.slug);
                });

                if(!parent_category) {

                    this.categories.filter(i => i.subcategories.length > 0).some((c) => {
                        c.subcategories.find(s => {
                            if(this.foodFilters.includes(s.slug) || this.promoFilters.includes(s.slug)) {
                                parent_category = c;
                                subcategory = s;
                            }
                        });
                    });
                }

                return parent_category ? subcategory ? parent_category.name + ' - ' + subcategory.name : parent_category.name : '';
            },

            selectedCategory () {
                if (this.foodFilters.length === 0) return null;

                return this.allCategories.find(c => c.slug === this.foodFilters[0]);
            },

            productsVisible () {
                let visible = 0;

                this.products.forEach(p => {
                    if (this.isVisible(p)) {
                        visible++;
                    }
                })

                return visible;
            },

            allCategories () {
                let categories = this.topLevelCategories.reduce((carry, value) => {
                    let subcategories = value.subcategories.reduce((subCarry, subValue) => subCarry.concat(subValue), []);

                    return carry.concat(value, subcategories);
                }, []);

                return categories;
            },

            topLevelCategories() {
                let staticCategories = this.staticCategories;
                let dynamicCategories = this.categories;

                return staticCategories.concat(dynamicCategories);
            },

            availableCategories () {
                let categories = [];

                this.topLevelCategories.filter(c => c.isSection).forEach(c => {
                    const match = this.products.find(p => {
                        if (c.slug === 'special') {
                            return p.special === 1;
                        }

                        const submatch = c.subcategories.find(sub => p.category_id === sub.id);

                        return (submatch || p.category_id === c.id) && p.active === 1;
                    });

                    if (match) {
                        categories.push(c);
                    }
                });

                return categories;
            }
        },

        methods: {
            hasProducts (category) {
                if (category.slug === 'special') {
                    return this.products.find(p => p.special === 1);
                }

                let ids = [category.id];

                if (category.subcategories) {
                    ids = ids.concat(category.subcategories.reduce((c, v) => c.concat([v.id]), []));
                }

                return this.products.find(p => p.active && ids.includes(p.category_id));
            },

            isCategoryVisible (category) {
                if (this.foodFilters.length === 0 && this.promoFilters.length === 0) {
                    return true;
                }

                let slugs = [category.slug];

                if (category.subcategories) {
                    slugs = slugs.concat(category.subcategories.reduce((c, v) => c.concat([v.slug]), []));
                } else {
                    let parentCategory = this.categories.find(c => c.subcategories.find(c2 => c2.slug === category.slug));

                    if (parentCategory) {
                        slugs = slugs.concat(parentCategory.slug);
                    }
                }

                return slugs.includes(this.foodFilters[0]) || slugs.includes(this.promoFilters[0]);
            },

            categorizedProducts (category) {
                let category_ids = [];
                let parent_category = this.allCategories.find(c => c.slug === category);

                category_ids.push(parent_category.id);

                switch (category) {
                    case 'special':
                        return this.products.filter(p => p.special === 1);

                    default:
                        return this.products.filter(p => (category_ids.includes(p.category_id)));
                }
            },

            isVisible (product) {
                let search = this.searchTerm === ''
                    || product.title.toUpperCase().indexOf(this.searchTerm.toUpperCase()) !== -1
                    || product.description.toUpperCase().indexOf(this.searchTerm.toUpperCase())  !== -1;

                // Find parent or subcategory...
                let parent_category_slug = '';
                let subcategory_slug = '';

                let parent_category = this.categories.find(c=> c.id === product.category_id);
                if(parent_category) parent_category_slug = parent_category.slug;
                else {
                    this.categories.filter(i => i.subcategories.length > 0).some((c) => {
                        c.subcategories.find(s => {
                            if(s.id === product.category_id) {
                                parent_category_slug = c.slug;
                                subcategory_slug = s.slug;
                            }
                        });
                    });
                }

                let food = this.foodFilters.length === 0 ||
                    this.foodFilters.includes(parent_category_slug) ||
                    this.foodFilters.includes(subcategory_slug);

                let promo = this.promoFilters.length === 0
                    || (this.promoFilters.includes('special') && product.special)
                    || (this.promoFilters.includes('locally_grown') && product.locally_grown)
                    || (this.promoFilters.includes('pinned') && product.pinned);

                let brand = this.brandFilters.length === 0
                    || (product.brand && this.brandFilters.includes(product.brand.id));

                return search && food && promo && brand;
            },

            handleQuickview (product) {
                this.quickviewProduct = product;
                this.showQuickview = true;
            },
        },
    }
</script>

<style>
.products {
    margin-bottom: 120px;
    min-height: 100vh;
}

.products .card-body {
    padding: 0.5rem;
    height: 100%;
}

.category-title {
    text-align: center;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #85C71E;
}

.subcategory-title {
    text-align: center;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

@media (min-width: 768px) {
    .category-title {
        font-size: 2.375rem;
        text-align: left;
    }

    .subcategory-title {
        font-size: 1.25rem;
        text-align: left;
    }
}
</style>