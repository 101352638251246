<template>
    <modal v-if="show" @close="$emit('close')" size="large" class="outlined referral" :closeable="false">
        <template #header class="p-0 referral">
            <div class='referral-header text-center w-100 pt-4 pb-3 p-0'>
                <img class="mb-2" src="/images/refer-friend-icon1.svg" width="15%" height="auto"/>
                <div v-if="referral_step == 1" class="px-5">REFER A FRIEND AND RECEIVE 10% OFF!</div>
                <div v-else>THANK YOU FOR YOUR REFERRAL</div>
            </div>
            <!-- <a href="#" class="close-referral-modal" @click.prevent="referral_step=1, reset_form(), $emit('close')"> X CLOSE</a> -->
            <div class="close-referral-modal" @click.prevent="referral_step=1, reset_form(), $emit('close')"> X &nbsp;&nbsp;CLOSE
            </div>
        </template>

        <template #body>
            <div class="container">
                <form id="referral_form" ref="referral_form" @submit.prevent="handleSubmit">
                    <div v-if="referral_step == 1" class="row my-4 mt2-if-small mb0-if-small">
                        <div class="col-md-6 mb0-if-small">
                            <h3 class="ml-2 mb-4 mb2-if-small">As Easy As 1-2-3:</h3>

                            <div class="d-flex align-items-center mb-4 mt-4 mb2-if-small">
                                <div class="step">1</div>
                                <p class="mb-0">Enter your name and email address</p>
                            </div>

                            <div class="d-flex align-items-center mb-4 mb2-if-small">
                                <div class="step">2</div>
                                <p class="mb-0">Enter the name and email address of the friend you would like to refer*</p>
                            </div>

                            <div class="d-flex align-items-center mb-4 mb2-if-small">
                                <div class="step">3</div>
                                <p class="mb-0">Once your referred friend places an order with their 10% discount code, you will be emailed your 10% discount code.</p>
                            </div>
                        </div>
                        <div class="col-md-6 mb0-if-small">
                            <div class="my-2">
                                <!--h 0 n # y p 0 t inputs-->
                                <input type="text" class="form_input" name="first_name" v-model="referralForm.first_name" placeholder="Enter your name" />
                                <input type="number" class="form_input" name="age" v-model="referralForm.age"/>
                                <!-- r # @ l inputs-->
                                <input type="text" class="form-control mb-2" v-model="referralForm.cust_name" placeholder="Your Name" required />
                                <input type="email" class="form-control mb-2" v-model="referralForm.cust_email" placeholder="Your Email Address" required />
                                <input type="text" class="form-control mb-2" v-model="referralForm.friend_name" placeholder="Friend's Name" required ref="friend_name"/>
                                <input type="email" class="form-control mb-2" v-model="referralForm.friend_email" placeholder="Friend's Email Address" required />
                            </div>
                            <div>
                                <transition name="fade">
                                    <div v-show="referral_error_msg != ''" class="alert alert-danger mb-2">
                                        {{ referral_error_msg }}
                                    </div>
                                </transition>
                            </div>
                            <div>
                                <button type="submit" class="btn btn-large referral-button" :disabled="referralForm.age != null || referralForm.first_name != '' || on_call">SUBMIT</button>
                                <p class="mt-2 mb-0"><small>*Valid only for friends who are not currently a Produce Express Customer</small></p>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="my-4 text-center w-50 mx-auto">
                            <p>Your friend has just been sent their code for 10% off their next order! Once they use their code, you will be emailed your 10% discount code.</p>
                        </div>
                        <div class="mb-4 text-center">
                            <button type="button" class="btn btn-large referral-button" @click.prevent="referral_step=1, $emit('close')">CLOSE WINDOW</button>
                        </div>
                    </div>
                </form>
            </div>
        </template>
    </modal>
</template>

<script>
    import Modal from '@/components/Modal'

    export default {
        components: {
            Modal,
        },

        props: ['show'],

        data() {
            return {
                referral_error_msg: '',
                referral_step: 1,
                referralForm: {
                    age: null,      // h 0 n # y p 0 t field
                    first_name: '', // h 0 n # y p 0 t field
                    cust_name: '',
                    cust_email: '',
                    friend_name: '',
                    friend_email: ''
                },
                on_call: false
            }
        },

        methods: {
            handleSubmit() {
                this.referral_error_msg = '';

                let vm = this;
                let data = vm.referralForm;

                vm.on_call = true;

                vm.axios.post(process.env.VUE_APP_API + '/refer_friend', data)
                    .then(function(response){
                        vm.on_call = false;

                        if(response.data.result == 'fail') {
                            vm.referral_error_msg = response.data.reason_msg;

                            if (response.data.reason == 'already_referred' || response.data.reason == 'already_customer' || response.data.reason == 'no_self_refer') {
                                vm.referralForm.friend_name = '';
                                vm.referralForm.friend_email = '';
                                vm.$refs.friend_name.focus();
                            }
                        }
                        else if(response.data.result == 'success') {
                            vm.reset_form();
                            vm.referral_step = 2;
                        }
                    })
                    .catch(function(error){
                        console.log(error);
                    });
            },

            reset_form() {
                this.friend_email_exists = false;

                this.referralForm.age = null;
                this.referralForm.first_name = '';

                this.referralForm.cust_name = '';
                this.referralForm.cust_email = '';
                this.referralForm.friend_name = '';
                this.referralForm.friend_email = '';
                this.referral_error_msg = '';
            }
        }

    }
</script>

<style scoped>

    .form_input
    {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
    }

    .close-modal {
        color: #F1283A;
        line-height: 1;
    }

    .close-referral-modal {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 9999;
        font-weight :bold;
        font-size: 1rem;
        color: white;
        border: 1px solid white;
        border-radius: 5px;
        padding: 0.25rem 0.5rem;
    }

    .close-referral-modal:hover {
        cursor: pointer;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
       opacity: 0;
    }

    h3 {
        font-weight: 900;
        white-space: nowrap;
        color: #F1283A;
    }

    .close-modal:hover {
        text-decoration: none;
    }

    .modal-body {
        overflow: visible;
    }

    input {
        border: 1px solid black;
    }
    ::placeholder {
        color: #B7B7B7;
        font-weight:bold
    }

    h1:after {
        display: block;
        content: '';
        border-bottom: 2px solid #F1283A;
        width: 180px;
        height: 1px;
        margin: 0.5rem auto;
    }

    .step {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        font-size: 1rem;
        font-weight: bold;
        content: "";
        background: #F1283A;
        color: #fff;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        flex: 0 0 auto;
        margin-right: 1rem;
    }

    .referral-button {
        background: #F1283A;
        color: white;
        font-weight: bold;
        width: 50%;
    }


    .col-md-6 {
        margin-bottom: 1rem;
    }

    @media (min-width: 768px) {
        .col-md-6 {
            margin-bottom: 0;
        }

        .step {
            width: 2rem;
            height: 2rem;
            font-size: 1.5rem;
        }
    }

    @media (max-width: 992px) {
        .mb2-if-small {
           margin-bottom: 0.5rem !important;
        }

        .mb0-if-small {
            margin-bottom: 0 !important;
        }

        .mt2-if-small {
            margin-top: 0.5rem !important;
        }
    }
</style>