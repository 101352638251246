<template>
    <div class="container-xl content">

         <div class="row">
            <div class="col-md-3 col-lg-2">
                <user-sidebar />
            </div>

            <div class="col-md-9 col-lg-10">
                <h1 class="mb-3">Order History</h1>

                Coming Soon
            </div>
         </div>
    </div>
</template>

<script>
import UserSidebar from '@/components/UserSidebar.vue';

export default {
    components: {
        UserSidebar,
    }
}
</script>

<style>

</style>