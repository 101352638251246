<template>
    <form action="#" @submit.prevent="handleLogin">
        <p>Don't have an account?
            <a href="#" @click="$emit('change-form', 'register')" class="text-underline">Sign Up</a>
        </p>

        <div class="form-group">
            <label for="">Email</label>
            <input type="text" v-model="form.email" class="form-control" ref="email"/>
            <span v-if="errors.email" class="text-danger">{{ errors.email[0] }}</span>
        </div>

        <div class="form-group">
            <label for="">Password</label>
            <input type="password" v-model="form.password" class="form-control" />
            <span v-if="errors.password" class="text-danger">{{ errors.password[0] }}</span>
        </div>

        <div class="d-flex justify-content-between align-items-center mt-5">
            <div>
                <input type="submit" class="btn btn-primary" value="Login" />
                <button type="button" @click="$emit('close')" class="btn btn-outline-secondary ml-2">Cancel</button>
            </div>

            <div>
                <a href="#" @click.prevent="$emit('change-form', 'password')">Forget your password?</a>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    data() {
        return {
            form: {
                email: '',
                password: '',
            },

            errors: '',
        }
    },

    methods: {
        handleLogin () {
            this.errors = '';

            this.$store.dispatch('signIn', this.form)
                .then(() => {
                    // ! To clear the query params that trigger login modal after a successful sign in.
                    // ! Empty catch method is to prevent Sentry from causing a navigationDuplicated error. We're doing
                    // ! this intentionally to clear the query parameters.
                    this.$router.replace({ query: null }).catch(() => {});

                    this.$emit('close');
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
    },

    mounted () {
        this.$refs.email.focus();
    }
}
</script>

<style>

</style>