<template>
    <div>
        <div id="bannerControls" class="carousel slide text-center outlet_description" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item" :class="{ active: index === 0 }" v-for="(slide, index) in announcements" :key="slide.id">
                    <!-- <div class="banner" :style="'background-image: url(' + imageUrl(slide) + ');'"> -->
                    <div class="banner">
                        <a :href="linkUrl(slide)"><img :src="imageUrl(slide)" /></a>
                        <div class="message" v-html="slide.message" v-if="slide.message != '<p><br></p>'"></div>
                        <!-- <div class="banner-overlay"></div> -->
                    </div>
                </div>
            </div>

            <a class="carousel-control-prev" href="#bannerControls" role="button" data-slide="prev" v-show="announcements.length > 1">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>

            <a class="carousel-control-next" href="#bannerControls" role="button" data-slide="next"  v-show="announcements.length > 1">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>

        <div class="sidebar-sticky">
            <product-sidebar></product-sidebar>
        </div>

        <div class="main">
            <div class="content">
                <product-grid></product-grid>
            </div>

            <order-summary></order-summary>
        </div>
    </div>
</template>

<script>
import ProductGrid from '@/components/ProductGrid'
import ProductSidebar from '@/components/ProductSidebar'
import OrderSummary from '@/components/OrderSummary'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'Home',

    props: {
        login: {
            require: false,
            type: String,
        },

        loginMessage: {
            require: false,
            type: String,
        }
    },

    components: {
        ProductGrid,
        ProductSidebar,
        OrderSummary,
    },

    metaInfo () {
        return {
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'Produce Express offers fresh produce, cheese, meat, dairy, local groceries and more! We offer home delivery or contactless pick up directly at our warehouse through our drive thru.' }
            ]
        }
    },

    data () {
        return {
            scrollPosition: 0,
            showModalAbout: true,
        }
    },

    computed: {
        ...mapGetters(['products']),

        ...mapState({
            currentUser: state => state.user.currentUser,
            announcements: state => state.announcements,
            categories: state => state.categories,
        }),
    },

    methods: {
        imageUrl (slide) {
            return process.env.VUE_APP_API + '/storage/announcement/' + slide.filename;
        },

        linkUrl (slide) {
            return slide.target_url ? '/' + slide.target_url : null;
        },
    },

    beforeRouteLeave (to, from, next) {
        this.scrollPosition = window.pageYOffset;
        next();
    },

    activated () {
        window.scrollTo(0, this.scrollPosition);
    },

    mounted () {
        window.$('.carousel').carousel({
            interval: 4000,
            ride: 'carousel',
        });
    },

    watch: {
        login: {
            immediate: true,
            handler (newValue) {
                if (newValue === '1' && !this.currentUser) {
                    this.$emit('login', this.loginMessage);
                }
            }
        },
    }
}
</script>

<style scoped>
    .banner {
        background: #d9d9d9;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .banner .message {
        color: #fff;
        text-align: center;
        position: absolute;
        z-index: 11;
        text-shadow: 1px 1px 5px #000;
        max-width: 70%;
    }

    .banner-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
    }

    .carousel-control-next,
    .carousel-control-prev {
        z-index: 12;
        opacity: 1;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        height: 32px;
        width: 32px;
        outline: black;
        background-size: 100%, 100%;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.35);
        background-image: none;
    }

    .carousel-control-next-icon:after
    {
        content: '>';
        font-size: 24px;
        color: #fff;
        line-height: 1.125;
        font-weight: bold;
        padding-left: 2px;
    }

    .carousel-control-prev-icon:after {
        content: '<';
        font-size: 24px;
        color: #fff;
        line-height: 1.125;
        font-weight: bold;
        padding-right: 2px;
    }

    @media (min-width: 768px) {
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            height: 48px;
            width: 48px;
        }
        .carousel-control-next-icon:after
        {
            font-size: 36px;
        }

        .carousel-control-prev-icon:after {
            font-size: 36px;
        }
    }

    @media (min-width: 992px) {
        .footer-summary {
            left: 260px;
        }

        .banner {
            border-bottom: 1px solid rgba(0,0,0,.125);
        }
    }
</style>

<style>
    .banner .message h1,
    .banner .message h2,
    .banner .message h3,
    .banner .message h4,
    .banner .message h5,
    .banner .message p {
        margin-bottom: 0;
        font-weight: 300;
    }

    .banner h1 {
        font-size: 2rem;
    }

    .banner h2 {
        font-size: 1.75rem;
    }

    .banner h3 {
        font-size: 1.5rem;
    }

    .banner p {
        font-size: 1rem;
    }

    @media (min-width: 768px) {
        .banner h1 {
            font-size: 2.5rem;
        }

        .banner h2 {
            font-size: 2.25rem;
        }

        .banner h3 {
            font-size: 2rem;
        }

        .banner p {
            font-size: 1.375rem;
        }
    }
</style>