<template>
    <div class="sidebar-user">
        <ul class="navbar-nav align-items-start">
            <li class="nav-item" :class="{ 'active': $router.currentRoute.name === 'orderHistory' }">
                <router-link :to="{ name: 'orderHistory' }" class="nav-link">
                    Order History
                </router-link>
            </li>
            <li class="nav-item" :class="{ 'active': $router.currentRoute.name === 'editProfile' }">
                <router-link :to="{ name: 'editProfile' }" class="nav-link">
                    Edit Profile
                </router-link>
            </li>
            <li>
                <a href="#" @click="handleLogout" class="nav-link">Sign Out</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    methods: {
        handleLogout () {
            this.$store.dispatch('signOut').then(() => {
                // TODO: Throws error first on edit profile page since currentUser disappears.
                this.$router.push('/');
            });
        }
    }
}
</script>

<style scoped>
    .sidebar-user {
        background: #efefef;
        padding: 0.5rem 1rem;
        border-radius: 6px;
        margin-bottom: 1rem;
    }

    .nav-item.active a {
        color: #FF5047;
    }

    @media (min-width: 768px) {
        .sidebar-user {
            min-height: calc(100vh - var(--header-height) - 2rem);
            margin-bottom: 0;
        }
    }
</style>