import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
import TextareaAutosize from 'vue-textarea-autosize'
import VueCookies from 'vue-cookies'
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

axios.defaults.withCredentials = true;

window.$ = window.jQuery = require('jquery');

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueMeta)
Vue.use(TextareaAutosize)
Vue.use(VueCookies)

Vue.directive('roundUp', {
    update: function (el) {
        if (el.value !== '') {
            var event = new Event('input', { bubbles: true });
            el.value = Math.ceil(el.value);
            el.dispatchEvent(event);
        }
    }
})

Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: value % 1 == 0 ? 0 : 2,
    });
    return formatter.format(value);
});

Sentry.init({
    Vue,
    dsn: "https://490b420b3a80480288b31e1723c06a03@o1112662.ingest.sentry.io/6142577",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "fresherproduce.me:8080", 'fresherproduce.innovel.app', "fresherproduce.ca", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_SENTRY_ENV,
    logErrors: true,
});

new Vue({
    router,
    store,

    metaInfo: {
        title: 'Home',
        titleTemplate: 'Produce Express | %s',

        meta: [
            { vmid: 'robots', name: 'robots', content: 'Index, Follow' }
        ],
    },

    render: h => h(App),
}).$mount('#app')

// * Fetch a CSRF token before each post request. See https://laravel.com/docs/8.x/sanctum#spa-authenticating
axios.interceptors.request.use(async config => {
    if (config.method === 'post') {
        await axios.get(process.env.VUE_APP_API + '/sanctum/csrf-cookie');
        return config;
    }

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// * Redirecting user to login page if their session is invalid
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if(error.response.status === 401) {
        store.commit('setCurrentUser', null);

        router.replace({
            name: 'home',
            query: {
                login: '1',
            }
        });
    }

    return Promise.reject(error);
});